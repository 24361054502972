/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  IconCard,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;
  const { email, profile } = currentUser?.attributes || {};
  const { firstName, lastName } = profile || {};
  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.mobileLogo}>
            <IconCard brand="logo" />
          </div>
          <div className={css.mobileMenuButtons}>
            <NamedLink name="LoginPage" className={css.menuButton}>
              <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
            <NamedLink name="SignupPage" className={css.menuButton}>
              <FormattedMessage id="TopbarMobileMenu.signupLink" />
            </NamedLink>
            <NamedLink name="SignupPage" className={css.menuButton}>
              Become a partner
            </NamedLink>
            <div className={css.dividerLine} />
            <div className={css.subLinks}>
              <div className={css.profileListLinks}>
                <NamedLink name="AreYouARestaurantPage" className={css.profileOtherLinks}>
                  <div>
                    <IconCard brand="restaurantsicon" />
                  </div>
                  <div className={css.otherLinksHeading}>
                    <div className={css.mainHeading}>Are you a Restaurant?</div>
                    <div className={css.subHeading}>Log in as a restaurant owner</div>
                  </div>
                </NamedLink>
              </div>
              <div className={css.profileListLinks}>
                <NamedLink name="LoginPage" className={css.profileOtherLinks}>
                  <div>
                    <IconCard brand="helpcenter" />
                  </div>
                  <div className={css.otherLinksHeading}>
                    <div className={css.mainHeading}>Help Centre</div>
                    <div className={css.subHeading}>Need assistance? We're here</div>
                  </div>
                </NamedLink>
              </div>
            </div>
          </div>
        </div>

        {/* {!isAuthenticated ?
          <div className={css.footer}>
            <NamedLink
              className={css.createNewListingLink}
              name="BecomeAExpertPage"
            >
              <FormattedMessage id="TopbarDesktop.expertTitle" />
            </NamedLink>
          </div>
          : null} */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={css.mobileLogo}>
        <IconCard brand="logo" />
      </div>
      <div className={css.mobileMenuButtons}>
        <div>
          <div className={css.profileListLinks}>
            <NamedLink name="ProfileSettingsPage" className={css.afterLoginProfileLinks}>
              <div className={css.leftSide}>
                <div>
                  <IconCard brand="grayprofile" />
                </div>
                <div className={css.otherLinksHeading}>
                  <div className={css.mainHeading}>
                    {firstName} {lastName}
                  </div>
                  <div className={css.subHeading}>{email}</div>
                </div>
              </div>
              <span className={css.editIcon}>
                <IconCard brand="editprofile" />
              </span>
            </NamedLink>
          </div>
          <div className={css.dividerLine} />
          <div className={css.subLinks}>
            <div className={css.profileListLinks}>
              {currentUser &&
              currentUser?.attributes?.profile?.publicData?.userRole === 'provider' ? (
                <NamedLink className={css.profileOtherLinks} name="ManageListingsPage">
                  <div>
                    <IconCard brand="listing" />
                  </div>
                  <div className={css.otherLinksHeading}>
                    <div className={css.mainHeading}>
                      <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
                    </div>
                  </div>
                </NamedLink>
              ) : (
                <React.Fragment />
              )}
            </div>
            <div className={css.profileListLinks}>
              <NamedLink
                name="InboxPage"
                className={css.profileOtherLinks}
                params={{ tab: currentUserHasListings ? 'sales' : 'orders', type: 'upcoming' }}
              >
                <div>
                  <IconCard brand="bookingicon" />
                </div>
                <div className={css.otherLinksHeading}>
                  <div className={css.mainHeading}>My Bookings</div>
                  <div className={css.subHeading}>Manage confirmed and pending bookings</div>
                </div>
              </NamedLink>
            </div>
            <div className={css.profileListLinks}>
              <NamedLink name="LoginPage" className={css.profileOtherLinks}>
                <div>
                  <IconCard brand="helpcenter" />
                </div>
                <div className={css.otherLinksHeading}>
                  <div className={css.mainHeading}>Help Centre</div>
                  <div className={css.subHeading}>Need assistance? We're here</div>
                </div>
              </NamedLink>
            </div>
          </div>
        </div>

        <div className={css.bottomButton}>
          {/* <NamedLink
            className={css.partnerButton}
            name="ManageListingsPage"
          >
            Become a partner
          </NamedLink> */}
          {currentUser && currentUser?.attributes?.profile?.publicData?.userRole === 'provider' ? (
            <NamedLink className={css.partnerButton} name="NewListingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
          ) : null}
          <InlineTextButton
            className={css.mobileLogoutButton}
            rootClassName={css.logoutButton}
            onClick={onLogout}
          >
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
      </div>
      {/* <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>

        {(currentUser && currentUser?.attributes?.profile?.publicData?.userRole === "provider") ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : <React.Fragment />}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div> */}

      {/* {currentUser && currentUser?.attributes?.profile?.publicData?.userRole === "provider" ?
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
        : null} */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
