// ================ Action types ================ //
export const ADD_TO_LISTING_REQUEST = 'app/AreYouRestaurantPage/ADD_TO_LISTING_REQUEST';
export const ADD_TO_LISTING_SUCCESS = 'app/AreYouRestaurantPage/ADD_TO_LISTING_SUCCESS';
export const ADD_TO_LISTING_ERROR = 'app/AreYouRestaurantPage/ADD_TO_LISTING_ERROR';
export const STORE_LISTING_DETAILS = 'app/AreYouRestaurantPage/STORE_LISTING_DETAILS';
export const ADD_PASSWORD = 'app/AreYouRestaurantPage/ADD_PASSWORD';
export const RESET_STATE = 'app/AreYouRestaurantPage/RESET_STATE';

// ================ Reducer ================ //

const initialState = {
  addToListingInProgress: null,
  addToListingSuccess: null,
  addToListingError: null,
  restaurantName: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  callback: null,
  password: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_LISTING_REQUEST:
      return {
        ...state,
        addToListingInProgress: true,
        addToListingInSuccess: null,
        addToListingInError: false,
      };
    case ADD_TO_LISTING_SUCCESS:
      return {
        ...state,
        addToListingInProgress: null,
        addToListingSuccess: true,
        addToListingError: null,
      };
    case ADD_TO_LISTING_ERROR:
      return {
        ...state,
        addToListingInProgress: null,
        addToListingSuccess: null,
        addToListingError: payload,
      };
    case STORE_LISTING_DETAILS:
      return {
        ...state,
        restaurantName: payload.rName || null,
        firstName: payload.fName || null,
        lastName: payload.lName || null,
        email: payload.email || null,
        phone: payload.phone || null,
        callback: payload.callback || null,
      };
    case ADD_PASSWORD:
      return { ...state, password: payload };
    case RESET_STATE:
      return {
        addToListingInProgress: null,
        addToListingSuccess: null,
        addToListingError: null,
        restaurantName: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        callback: null,
        password: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const addToListingRequest = () => ({ type: ADD_TO_LISTING_REQUEST });
export const addToListingSuccess = () => ({ type: ADD_TO_LISTING_SUCCESS });
export const addToListingError = error => ({ type: ADD_TO_LISTING_ERROR, payload: error });
export const storeListingDetails = payload => ({ type: STORE_LISTING_DETAILS, payload });
export const addPasswordToListing = password => ({ type: ADD_PASSWORD, payload: password });
export const resetState = () => ({ type: RESET_STATE });

// ================ Thunks ================ //

export const storeDetails = params => dispatch => {
  dispatch(storeListingDetails(params));

  // console.log('called', params);
};

export const addPassword = password => dispatch => dispatch(addPasswordToListing(password));

export const addToListing = () => async (dispatch, getState) => {
  dispatch(addToListingRequest());
  const { AreYouARestaurantPage } = getState();

  delete AreYouARestaurantPage.addToListingError;
  delete AreYouARestaurantPage.addToListingInError;
  delete AreYouARestaurantPage.addToListingInProgress;
  delete AreYouARestaurantPage.addToListingInSuccess;
  delete AreYouARestaurantPage.addToListingSuccess;

  // console.log('body ===', AreYouARestaurantPage);

  return fetch(process.env.REACT_APP_SHEET_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SHEETS_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: { ...AreYouARestaurantPage, id: 'INCREMENT' } }),
  })
    .then(async res => {
      if (!res.ok) {
        console.log(await res.json());
        dispatch(addToListingError());
        return res;
      }
      dispatch(addToListingSuccess());
      setTimeout(() => {
        dispatch(resetState());
      }, 100);
      return res.json();
    })
    .catch(error => {
      console.log(error);
      dispatch(addToListingError());
      return error;
    });
};
