import React, { Component } from 'react';
import { string } from 'prop-types';
import { /* DayPickerRangeController, */ DayPickerSingleDateController } from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';

import { IconArrowHead } from '../../components';
import css from './DateRangeController.module.css';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const defaultProps = {
  startDateOffset: undefined,
  endDateOffset: undefined,

  // calendar presentation and interaction related props

  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  isRTL: false,
  initialVisibleMonth: null,
  // This gets default value at FieldDateRangeController
  firstDayOfWeek: 0,
  numberOfMonths: 1,
  daySize: 38,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // This gets default value at FieldDateRangeController
  isOutsideRange: day => false,
  isDayHighlighted: () => {},

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  // displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
};

class DateRangeController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.value && props.value.date ? moment(props.value.date) : null,
      focused: true,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  onDateChange(value) {
    this.setState({ date: value });

    this.props.onChange({ date: value ? value.toDate() : null });
  }

  onFocusChange() {
    // Force the focused states to always be truthy so that date is always selectable
    this.setState({ focused: true });
  }

  onReset() {
    this.setState({
      date: null,
    });
  }

  render() {
    // Removing Final Form field props: name, value, onChange, onFocus, meta, children, render
    const {
      rootClassName,
      className,
      name,
      value,
      onChange,
      onFocus,
      meta,
      children,
      render,
      ...controllerProps
    } = this.props;

    const classes = classNames(rootClassName || css.inputRoot, className);

    return (
      <div className={classes}>
        <DayPickerSingleDateController
          {...controllerProps}
          onDateChange={this.onDateChange}
          date={this.state.date}
          onFocusChange={this.onFocusChange}
          focused={this.state.focused}
        />
      </div>
    );
  }
}

DateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
  ...defaultProps,
};

DateRangeController.propTypes = {
  rootClassName: string,
  className: string,
};

export default DateRangeController;
