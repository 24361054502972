import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx?.attributes ? tx?.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search,config) => (dispatch, getState, sdk) => {
  const { tab,type } = params;
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: getAllTransitionsForEveryProcess(),
    include: [
      'listing',
      'listing.images',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'protectedData',
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType','publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
     'fields.image': ['variants.square-small', 'variants.square-small2x'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
    page,
    perPage: INBOX_PAGE_SIZE,
  };
 

 
  if (tab === 'orders' && type === 'upcoming') {
    apiQueryParams.lastTransitions = [
      "transition/confirm-payment",
      "transition/accept",
      'transition/inquire',"transition/request-payment", "transition/inquire-without-payment"
    ];
  }
  if (tab === 'sales' && type === 'upcoming') {
    apiQueryParams.lastTransitions = [
      "transition/confirm-payment",
      "transition/accept",
      'transition/inquire',"transition/request-payment", "transition/inquire-without-payment"
    ];
  }
  if (tab === 'orders' && type === 'cancelled') {
    apiQueryParams.lastTransitions = [
      "transition/expire-payment",
      'transition/auto-complete',
      "transition/cancel",
      "transition/expire",
      "transition/operator-decline",
      "transition/decline",
    ];
  }
  if (tab === 'sales' && type === 'cancelled') {
    apiQueryParams.lastTransitions = [
      "transition/expire-payment",
      'transition/auto-complete',
      "transition/cancel",
      "transition/expire",
      "transition/operator-decline",
      "transition/decline",
    ];
  }
  if (tab === 'orders' && type === 'completed') {
    apiQueryParams.lastTransitions = [
      "transition/operator-accept",
      "transition/complete",
      'transition/auto-complete',
      'transition/expire-review-period',
      "transition/expire-customer-review-period",
      "transition/review-2-by-customer",
      "transition/review-1-by-customer",
      "transition/operator-complete"
    ];
  }
  if (tab === 'sales' && type === 'completed') {
    apiQueryParams.lastTransitions = [
      "transition/operator-accept",
      "transition/complete",
      'transition/auto-complete',
      'transition/expire-review-period',
      "transition/review-1-by-provider",
      "transition/review-2-by-provider",
       "transition/expire-provider-review-period",
      "transition/operator-complete"
    ];
  }
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
