import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';
import IconCard from '../IconCard/IconCard';

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    'Total Due Today to Secure Your Date'
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          {totalLabel}
          <span className={css.tooltipHeading}>
            <IconCard brand="info" />
            <div className={css.minimumSpendTooltip}>
              <div className={css.tooltipRow}>
                <span className={css.bookingHeading}>
                  The total payment required today to confirm and secure your selected date on the
                  venue's calendar. BUYOUT will block this date, ensuring no other bookings can
                  overlap with yours. 100% of this deposit goes directly to the restaurant and
                  counts towards your minimum F&B spend. The venue will contact you directly within
                  one business day to proceed with the arrangements. Rest assured, if the venue is
                  unable to accommodate your event, any deposits paid will be returned to you.
                </span>
              </div>
            </div>
          </span>
        </div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
