import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const UserNavComponent = props => {
  const { className, rootClassName, currentPage,  currentUser} = props;
  const classes = classNames(rootClassName || css.root, className);
  // {currentUser && currentUser?.attributes?.profile?.publicData?.userRole === "provider" ?
  const tabs = [

    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];
  const listingtab = tabs.filter(tab => tab.linkProps.name !== 'ManageListingsPage');

 const listtab= currentUser && currentUser?.attributes?.profile?.publicData?.userRole === "provider" ?
  tabs
  :
  listingtab

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={listtab}  skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  currentUser: propTypes.currentUser,
  currentPage: string.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser
   
  } = state.user;

  return {
    currentUser
  };
};

const UserNav = compose(
  withRouter,
  connect(
    mapStateToProps,

  )
)(UserNavComponent);

export default UserNav;
// export default UserNav;
