import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconAmenities.module.css';
import certifiedFullyPrivate from '../../assets/images/amenities/certified_fully_private.png';


const IconAmenities = props => {
  const { className, rootClassName, name } = props;

  const classes = classNames(rootClassName || css.root, className);

  switch (name) {
    case 'certified_fully_private':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="20" height="20" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2373 4010 c-699 -42 -1350 -307 -1878 -766 -189 -164 -423 -426
              -468 -523 -18 -38 -21 -65 -21 -161 0 -142 9 -166 122 -306 193 -241 473 -488
              752 -665 689 -438 1531 -586 2355 -413 613 129 1214 476 1645 949 200 220 240
              292 240 435 0 144 -40 215 -242 437 -636 696 -1558 1069 -2505 1013z m458
              -244 c522 -119 901 -551 960 -1096 29 -264 -49 -565 -208 -802 -243 -363 -705
              -584 -1130 -540 -229 24 -454 110 -629 241 -445 332 -618 928 -417 1435 71
              180 170 325 313 459 185 174 441 295 691 327 114 14 298 3 420 -24z m-1289
              -163 c-89 -77 -227 -272 -297 -418 -155 -325 -182 -718 -73 -1065 60 -192 184
              -403 325 -554 34 -36 60 -66 59 -66 -14 0 -209 86 -309 136 -361 181 -718 467
              -955 767 -70 89 -88 137 -72 197 14 50 103 163 244 309 282 289 602 507 981
              667 113 48 126 51 97 27z m2115 -19 c377 -155 715 -383 999 -675 141 -146 230
              -259 244 -309 16 -60 -2 -108 -72 -197 -237 -299 -596 -587 -957 -768 -102
              -51 -294 -135 -308 -135 -2 0 25 30 59 66 188 197 325 471 373 749 23 133 23
              357 0 490 -48 277 -179 541 -371 749 -34 36 -59 66 -58 66 2 0 43 -16 91 -36z"/>
            <path d="M2475 3090 c-202 -32 -363 -175 -431 -380 -12 -37 -18 -85 -18 -150
              0 -150 51 -274 156 -378 103 -103 230 -156 378 -156 241 0 438 147 516 384 12
              37 18 85 18 150 0 241 -153 446 -381 513 -67 20 -174 28 -238 17z m210 -239
              c62 -28 130 -94 162 -160 36 -73 38 -181 6 -253 -30 -66 -95 -133 -162 -165
              -80 -39 -182 -39 -262 0 -67 32 -132 99 -162 165 -29 65 -29 179 0 243 73 163
              261 239 418 170z"/>
          </g>
        </svg>
      );
    case 'av_available':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M1037 5109 c-36 -21 -47 -63 -47 -179 l0 -110 -108 0 c-108 0 -108 0
            -138 -31 l-29 -30 -3 -192 -3 -192 -34 -35 c-68 -72 -65 -40 -65 -662 0 -525
            2 -566 19 -603 10 -23 32 -52 48 -66 l31 -26 3 -175 c4 -175 4 -176 37 -238
            24 -46 32 -76 32 -114 1 -124 44 -348 100 -516 98 -291 238 -526 459 -767 155
            -169 245 -294 316 -438 101 -205 140 -350 152 -555 6 -109 19 -152 52 -170 13
            -6 253 -10 701 -10 l682 0 27 28 c26 25 28 35 35 135 13 204 55 370 137 548
            52 112 58 143 34 188 -28 56 -118 66 -163 18 -30 -32 -97 -179 -137 -302 -37
            -113 -75 -301 -75 -372 l0 -43 -111 0 -112 0 7 78 c18 204 85 441 181 637 88
            183 181 314 341 487 270 290 412 555 477 886 l22 112 108 0 109 0 -7 -42 c-43
            -274 -121 -498 -251 -719 -55 -94 -58 -136 -15 -180 24 -23 38 -29 74 -29 28
            0 53 7 66 18 35 28 144 231 199 369 68 170 117 365 137 544 14 123 21 153 47
            207 31 63 32 66 36 240 l5 175 30 26 c16 14 38 43 48 66 17 37 19 78 19 603 0
            534 -1 565 -19 600 -11 20 -33 50 -50 66 l-31 30 0 167 c0 194 -4 220 -38 254
            -23 24 -31 25 -133 25 l-109 0 0 110 c0 154 -21 190 -110 190 -33 0 -48 -6
            -71 -29 -29 -29 -29 -30 -29 -150 l0 -121 -106 0 c-171 0 -174 -5 -174 -264
            l0 -185 -29 -20 c-16 -12 -40 -42 -53 -69 l-23 -47 0 -545 c0 -300 4 -562 8
            -584 5 -24 24 -55 52 -85 l43 -46 4 -175 c4 -169 5 -177 32 -229 25 -49 27
            -60 21 -120 -32 -309 -169 -598 -407 -861 -197 -216 -280 -327 -364 -484 l-44
            -81 0 747 0 746 27 54 c27 51 28 60 31 229 l4 176 38 37 c66 64 65 51 65 671
            l0 560 -27 46 c-15 25 -39 54 -53 63 l-25 16 0 186 c0 259 -3 264 -174 264
            l-106 0 0 121 c0 120 0 121 -29 150 -23 23 -38 29 -71 29 -89 0 -110 -36 -110
            -190 l0 -110 -105 0 c-102 0 -106 -1 -137 -29 l-33 -29 -3 -194 -4 -193 -33
            -35 c-67 -69 -65 -50 -65 -667 0 -520 2 -561 19 -598 10 -23 32 -53 50 -67
            l31 -27 0 -128 c0 -156 12 -233 45 -296 l25 -49 0 -746 0 -747 -44 81 c-83
            155 -193 303 -350 473 -48 51 -106 119 -130 150 -162 212 -263 457 -295 716
            -8 67 -7 73 23 135 30 63 31 67 34 235 l4 170 43 46 c28 30 47 61 52 85 4 22
            8 284 8 584 l0 545 -23 47 c-13 27 -37 57 -53 69 l-29 20 0 185 c0 259 -3 264
            -174 264 l-106 0 0 121 c0 120 0 121 -29 150 -24 23 -38 29 -73 29 -24 0 -51
            -5 -61 -11z m233 -604 l0 -105 -175 0 -175 0 0 105 0 105 175 0 175 0 0 -105z
            m1460 0 l0 -105 -175 0 -175 0 0 105 0 105 175 0 175 0 0 -105z m1470 0 l0
            -105 -175 0 -175 0 0 105 0 105 175 0 175 0 0 -105z m-2825 -830 l0 -510 -277
            -3 -278 -2 0 515 0 515 278 -2 277 -3 0 -510z m1463 3 l-3 -513 -277 -3 -278
            -2 0 515 0 515 280 0 280 0 -2 -512z m1462 -3 l0 -515 -277 2 -278 3 -3 500
            c-1 275 0 506 3 513 3 9 69 12 280 12 l275 0 0 -515z m-3032 -866 l-3 -141
            -33 -29 c-31 -28 -36 -29 -130 -29 -162 0 -182 24 -182 218 l0 122 176 0 176
            0 -4 -141z m1462 5 l0 -136 -35 -35 -35 -35 -109 4 c-158 5 -171 22 -171 226
            l0 112 175 0 175 0 0 -136z m1470 14 c0 -194 -20 -218 -182 -218 -94 0 -99 1
            -130 29 l-33 29 -3 141 -4 141 176 0 176 0 0 -122z m-2986 -450 c78 -412 195
            -650 460 -938 125 -136 194 -222 264 -327 155 -237 263 -543 290 -825 l8 -88
            -107 0 -108 0 -6 57 c-27 255 -110 497 -243 711 -70 113 -126 184 -263 334
            -64 71 -146 170 -182 221 -77 107 -171 284 -221 414 -42 109 -93 310 -102 398
            l-7 65 106 0 c103 0 107 -1 111 -22z m1456 -1078 l0 -1100 -110 0 -110 0 0
            1100 0 1100 110 0 110 0 0 -1100z"/>
            <path d="M3620 1283 c-57 -21 -85 -94 -55 -148 52 -98 195 -58 195 56 0 42
            -56 99 -96 98 -16 0 -36 -3 -44 -6z"/>
          </g>
        </svg>
      )
    case 'celebrated_for_product_launches':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M1723 4914 c-25 -25 -26 -29 -20 -112 22 -328 226 -611 529 -736 48
              -20 94 -36 102 -36 14 0 16 -24 16 -183 l0 -182 -52 -13 c-101 -24 -194 -107
              -229 -204 l-13 -38 -198 0 c-125 0 -206 -4 -222 -11 -31 -14 -49 -57 -40 -93
              14 -56 15 -56 426 -56 353 0 378 -1 378 -17 0 -24 -17 -87 -42 -155 -19 -55
              -22 -58 -54 -58 -19 0 -85 17 -147 37 -109 37 -116 38 -252 37 -134 0 -144 -2
              -247 -37 -148 -51 -235 -52 -383 -2 -57 19 -132 38 -166 42 -63 8 -63 8 -75
              48 -6 22 -14 55 -17 73 l-6 32 173 0 c167 0 173 1 194 23 30 32 29 81 -3 112
              l-24 25 -226 0 -226 0 -26 -26 c-25 -25 -26 -28 -20 -122 8 -126 27 -195 87
              -317 102 -205 272 -355 490 -431 l65 -23 3 -180 2 -181 -27 -5 c-77 -17 -121
              -41 -179 -99 -41 -41 -66 -76 -76 -106 l-15 -45 -587 -5 c-573 -5 -588 -6
              -603 -25 -12 -16 -14 -41 -10 -125 6 -120 29 -205 88 -321 39 -79 147 -222
              175 -233 38 -15 83 -6 103 20 36 46 26 79 -47 156 -20 21 -33 40 -30 44 4 3
              46 -8 95 -25 161 -55 308 -51 488 11 78 27 103 31 185 30 80 0 111 -6 198 -35
              56 -19 105 -36 108 -39 12 -13 -123 -120 -199 -157 -148 -73 -284 -94 -427
              -66 -144 29 -165 30 -189 6 -14 -14 -21 -34 -21 -60 0 -46 28 -75 85 -85 l35
              -7 0 -180 c0 -135 -3 -180 -12 -183 -109 -30 -131 -40 -182 -87 -74 -67 -109
              -139 -110 -226 -2 -110 -35 -103 509 -103 544 0 511 -7 509 103 -1 86 -36 159
              -109 225 -51 47 -123 82 -167 82 -17 0 -18 13 -18 184 l0 184 53 17 c28 10 84
              33 122 52 218 108 388 322 443 559 l19 77 17 -74 c24 -101 103 -260 169 -340
              103 -124 240 -220 386 -269 l71 -25 0 -182 0 -183 -28 0 c-46 0 -147 -57 -191
              -107 -50 -56 -81 -136 -81 -208 0 -46 4 -56 26 -74 26 -21 37 -21 484 -21 447
              0 458 0 484 21 22 18 26 28 26 74 0 72 -31 152 -81 208 -44 50 -145 107 -191
              107 l-28 0 0 183 0 184 43 12 c74 22 220 100 287 154 153 125 266 312 306 507
              6 33 8 31 25 -43 67 -283 286 -520 567 -611 l52 -18 0 -184 c0 -171 -1 -184
              -18 -184 -44 0 -116 -35 -167 -82 -73 -66 -108 -139 -109 -225 -2 -110 -35
              -103 509 -103 544 0 511 -7 509 103 -1 87 -36 159 -110 226 -51 47 -73 57
              -181 87 -10 3 -13 48 -13 182 l0 178 58 18 c338 107 591 439 592 779 0 121 11
              117 -283 117 -242 0 -244 0 -265 -23 -27 -29 -28 -77 -3 -108 19 -23 24 -24
              205 -27 102 -2 186 -6 186 -10 -1 -23 -32 -135 -49 -175 l-21 -49 -43 7 c-23
              4 -91 23 -152 42 -101 32 -120 35 -230 35 -112 0 -127 -2 -235 -38 -101 -34
              -126 -38 -210 -38 -81 0 -109 5 -190 33 -52 18 -124 36 -160 39 -63 7 -65 8
              -76 42 -6 19 -14 52 -18 73 l-7 37 360 0 c243 0 368 4 384 11 46 21 60 81 28
              120 -19 23 -24 24 -222 29 l-202 5 -15 45 c-10 30 -35 65 -76 106 -58 58 -102
              82 -179 99 l-27 5 2 181 3 180 65 23 c218 76 388 226 490 431 60 122 79 191
              87 317 6 94 5 97 -20 122 l-26 26 -579 0 -578 0 -13 38 c-20 54 -67 116 -114
              150 -41 29 -122 62 -152 62 -13 0 -15 29 -15 183 l0 183 47 13 c27 7 82 29
              124 50 242 122 414 347 463 610 24 126 22 186 -9 216 l-24 25 -503 0 c-548 0
              -537 1 -558 -56 -11 -29 5 -81 30 -94 12 -6 193 -10 464 -10 l446 0 0 -24 c0
              -27 -44 -172 -59 -195 -13 -19 -74 -10 -191 31 -90 31 -102 33 -240 33 -141 0
              -148 -1 -240 -35 -86 -32 -105 -35 -200 -35 -93 0 -116 4 -202 33 -53 18 -124
              36 -159 40 l-63 7 -13 50 c-25 97 -27 95 85 95 85 0 101 3 120 20 33 31 30 92
              -4 119 -24 19 -40 21 -181 21 -152 0 -154 0 -180 -26z m1245 -483 l114 -38
              -64 -57 c-76 -69 -214 -140 -313 -161 -230 -48 -461 22 -634 192 -46 46 -82
              85 -79 88 2 3 45 -9 94 -27 83 -29 98 -31 229 -32 135 0 144 1 250 36 94 32
              123 37 200 37 77 0 106 -5 203 -38z m-358 -601 l0 -170 -50 0 -50 0 0 170 0
              170 50 0 50 0 0 -170z m211 -352 c22 -12 46 -32 54 -45 l15 -23 -330 0 -331 0
              15 22 c9 13 33 33 54 45 36 22 46 23 261 23 211 0 225 -1 262 -22z m1283 -260
              c-4 -18 -18 -69 -33 -113 l-26 -80 -51 1 c-30 1 -91 16 -145 35 -90 32 -101
              33 -234 33 -134 0 -144 -1 -253 -37 -155 -50 -229 -51 -381 -2 -59 19 -136 38
              -169 41 -61 7 -62 8 -72 43 -6 20 -13 53 -16 74 l-7 37 696 0 697 0 -6 -32z
              m-1522 -110 c48 -198 184 -385 366 -502 70 -45 205 -106 236 -106 15 0 16 -21
              14 -187 l-3 -187 -41 -8 c-95 -19 -204 -116 -234 -209 l-13 -39 -347 0 -347 0
              -13 39 c-30 93 -139 190 -234 209 l-41 8 -3 187 c-2 166 -1 187 14 187 31 0
              166 61 236 106 182 117 318 304 366 502 9 39 19 72 22 72 3 0 13 -33 22 -72z
              m-486 -203 c57 -19 112 -35 122 -35 11 -1 -5 -21 -47 -61 -78 -72 -214 -143
              -316 -164 -205 -43 -430 12 -588 143 -56 47 -133 130 -126 136 2 2 45 -10 94
              -27 85 -30 98 -32 235 -31 140 1 149 2 250 37 146 51 229 51 376 2z m1712 -2
              c67 -21 122 -41 122 -45 0 -16 -138 -119 -204 -152 -274 -138 -585 -87 -805
              132 -46 45 -81 85 -79 87 3 3 45 -9 94 -27 85 -31 96 -33 234 -33 138 0 150 2
              235 32 114 40 115 40 205 42 60 1 99 -6 198 -36z m-2048 -603 l0 -170 -50 0
              -50 0 0 170 0 170 50 0 50 0 0 -170z m1700 0 l0 -170 -50 0 -50 0 0 170 0 170
              50 0 50 0 0 -170z m-1482 -362 c23 -15 46 -37 52 -48 11 -20 8 -20 -320 -20
              -181 0 -330 4 -330 8 0 15 54 60 90 76 29 13 74 16 251 13 l216 -2 41 -27z
              m1677 13 c40 -19 93 -67 83 -76 -3 -3 -153 -4 -334 -3 l-329 3 38 37 c21 21
              52 42 70 47 18 5 122 10 232 10 174 0 205 -2 240 -18z m-2105 -248 c-1 -24
              -32 -135 -49 -176 l-21 -49 -43 7 c-23 4 -91 23 -152 42 -101 32 -120 35 -230
              35 -113 0 -127 -2 -230 -37 -97 -34 -122 -38 -210 -39 -89 0 -110 3 -192 33
              -51 18 -123 36 -160 39 -67 7 -68 8 -79 42 -6 19 -14 52 -18 73 l-7 37 696 0
              c382 0 695 -3 695 -7z m1700 -17 c0 -26 -44 -172 -58 -194 -12 -17 -104 -2
              -197 33 -142 54 -321 51 -481 -6 -76 -27 -103 -32 -189 -33 -89 -1 -111 3
              -200 33 -55 19 -127 37 -160 40 -68 7 -80 21 -91 105 l-7 46 692 0 691 0 0
              -24z m-281 -323 l114 -37 -52 -48 c-279 -257 -699 -244 -969 29 -41 41 -72 77
              -69 80 3 2 49 -10 104 -28 90 -30 109 -33 223 -32 115 0 133 2 220 32 177 61
              249 62 429 4z m1701 2 c55 -17 103 -34 106 -37 12 -13 -123 -120 -199 -158
              -115 -56 -191 -74 -317 -74 -188 0 -339 63 -479 201 -47 45 -82 85 -79 88 3 4
              46 -7 95 -24 156 -53 305 -51 468 5 151 52 243 52 405 -1z m-3760 -600 l0
              -165 -50 0 -50 0 0 165 0 165 50 0 50 0 0 -165z m1700 0 l0 -165 -50 0 -50 0
              0 165 0 165 50 0 50 0 0 -165z m1700 0 l0 -165 -50 0 -50 0 0 165 0 165 50 0
              50 0 0 -165z m-3222 -341 c37 -15 92 -61 92 -76 0 -4 -146 -8 -325 -8 -179 0
              -325 4 -325 8 0 15 54 61 90 76 51 22 415 23 468 0z m1697 5 c22 -6 55 -27 75
              -48 l35 -36 -168 -3 c-92 -1 -242 -1 -335 0 l-167 3 38 37 c21 21 52 42 70 47
              46 13 404 13 452 0z m1713 -5 c37 -15 92 -61 92 -76 0 -4 -146 -8 -325 -8
              -179 0 -325 4 -325 8 0 15 54 61 90 76 51 22 415 23 468 0z"/>
          </g>
        </svg>
      )
    case 'chosen_for_vip_dinners':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2101 4514 c-27 -34 -27 -66 2 -99 17 -20 34 -27 80 -32 l58 -6 -3
              -80 -3 -81 -33 -8 c-18 -5 -55 -30 -82 -56 -48 -47 -54 -59 -89 -197 l-11 -40
              -115 -40 c-64 -21 -163 -62 -222 -91 l-106 -52 -41 25 c-118 75 -209 92 -292
              54 l-42 -19 -56 57 -56 57 30 29 c42 40 55 86 36 123 -17 32 -34 42 -75 42
              -21 0 -83 -57 -334 -308 -328 -329 -330 -331 -287 -384 36 -46 98 -39 149 16
              l25 26 57 -56 58 -57 -17 -31 c-11 -21 -17 -55 -17 -106 0 -72 2 -79 48 -157
              l48 -81 -49 -99 c-27 -54 -68 -153 -92 -220 -42 -120 -42 -121 -77 -128 -141
              -26 -224 -81 -258 -169 l-18 -46 -83 0 -84 0 0 60 c0 48 -4 63 -22 80 -28 26
              -78 26 -106 0 -22 -21 -22 -21 -22 -460 l0 -439 26 -20 c34 -27 64 -27 98 0
              23 18 26 28 26 80 l0 59 84 0 84 0 12 -36 c7 -20 33 -58 59 -84 46 -48 58 -54
              197 -90 l42 -10 37 -107 c41 -121 115 -283 171 -376 90 -148 227 -323 282
              -359 33 -22 122 -24 160 -4 15 8 163 151 329 318 323 325 334 340 319 421 -4
              19 -31 68 -60 109 -77 104 -121 190 -152 293 -25 81 -27 105 -28 245 0 141 3
              163 27 243 91 294 300 506 597 603 78 26 98 28 240 28 167 1 216 -7 333 -55
              261 -106 449 -318 529 -596 33 -113 32 -333 0 -448 -30 -104 -89 -223 -157
              -314 -30 -40 -57 -89 -61 -108 -15 -81 -3 -97 304 -407 158 -158 305 -301 326
              -317 32 -24 49 -29 95 -29 67 0 91 14 159 92 157 180 308 444 383 670 l31 98
              42 10 c139 36 151 42 197 90 26 26 52 64 59 84 l12 36 84 0 84 0 0 -59 c0 -52
              3 -62 26 -80 34 -27 64 -27 98 0 l26 20 0 439 c0 439 0 439 -22 460 -28 26
              -78 26 -106 0 -18 -17 -22 -32 -22 -80 l0 -60 -84 0 -83 0 -18 46 c-34 88
              -117 143 -259 169 -34 7 -37 11 -55 69 -27 88 -75 204 -121 296 l-40 80 47 81
              c58 100 69 176 35 251 l-20 46 59 58 59 58 37 -36 c36 -34 54 -42 95 -39 29 2
              58 42 58 80 0 33 -20 56 -303 338 -282 283 -305 303 -338 303 -48 0 -79 -30
              -79 -77 0 -29 8 -45 37 -75 l37 -38 -58 -59 -58 -59 -46 20 c-75 34 -151 23
              -251 -35 l-81 -47 -40 20 c-65 33 -108 24 -130 -30 -21 -51 -1 -79 99 -138
              399 -232 698 -598 840 -1027 73 -222 96 -389 88 -640 -14 -416 -152 -782 -418
              -1106 -29 -35 -54 -66 -56 -69 -2 -2 -132 124 -288 280 l-284 283 33 41 c83
              103 157 259 191 401 23 99 31 284 15 386 -61 399 -335 722 -715 842 -214 67
              -471 61 -680 -16 -188 -69 -382 -221 -494 -385 -131 -194 -190 -406 -178 -646
              11 -215 77 -403 199 -562 l46 -61 -284 -284 c-156 -155 -285 -281 -287 -279
              -1 3 -26 32 -54 65 -119 142 -254 377 -317 553 -37 104 -73 253 -92 378 -20
              132 -15 433 10 563 129 689 616 1240 1281 1445 160 50 278 67 490 73 220 5
              324 -5 512 -53 89 -23 104 -24 129 -12 64 30 56 110 -13 142 -34 16 -43 26
              -48 54 -26 140 -81 224 -169 257 l-46 18 0 78 0 79 39 0 c50 0 99 18 111 40
              16 31 11 79 -10 100 -19 19 -33 20 -459 20 l-440 0 -20 -26z m619 -214 l0 -80
              -160 0 -160 0 0 80 0 80 160 0 160 0 0 -80z m163 -253 c14 -10 50 -88 44 -93
              -1 -1 -45 5 -97 12 -161 23 -579 14 -624 -14 -18 -10 5 62 26 85 l21 23 307 0
              c231 0 310 -3 323 -13z m-1910 -479 l-113 -113 -55 55 -55 55 112 112 113 113
              55 -55 55 -55 -112 -112z m3292 112 l110 -110 -58 -57 -57 -58 -112 112 -113
              113 55 55 c30 30 57 55 60 55 3 0 55 -50 115 -110z m-2875 -25 c17 -9 30 -17
              30 -19 0 -2 -33 -28 -72 -58 -112 -84 -266 -236 -359 -353 l-84 -106 -19 39
              c-11 24 -16 49 -13 63 4 13 102 120 220 237 229 228 233 230 297 197z m2642
              -202 c182 -182 218 -223 218 -246 0 -27 -28 -87 -40 -87 -3 0 -30 35 -60 77
              -71 98 -275 302 -373 373 -42 30 -77 57 -77 60 0 12 58 39 85 40 26 0 60 -30
              247 -217z m-3457 -1208 c-8 -55 -14 -174 -15 -265 0 -147 5 -204 26 -329 5
              -34 5 -34 -27 -27 -18 3 -43 16 -56 28 l-23 21 0 307 c0 231 3 310 13 323 10
              15 87 50 94 43 1 -1 -4 -47 -12 -101z m4032 76 c12 -6 23 -18 27 -27 3 -8 6
              -152 6 -318 l0 -303 -23 -21 c-13 -12 -38 -25 -56 -28 -30 -6 -32 -5 -27 17
              34 148 34 524 0 681 -5 26 -4 27 24 19 16 -5 38 -14 49 -20z m-4287 -341 l0
              -160 -85 0 -85 0 0 160 0 160 85 0 85 0 0 -160z m4650 0 l0 -160 -85 0 -85 0
              0 160 0 160 85 0 85 0 0 -160z"/>
          </g>
        </svg>
      );
    case 'client_grade_food_options':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M576 4889 c-24 -35 -20 -69 17 -121 46 -64 85 -154 102 -235 15 -68
       18 -223 6 -277 l-6 -29 -47 51 c-76 81 -130 204 -182 417 -27 108 -40 125 -93
       125 -48 0 -66 -24 -96 -131 -86 -305 -92 -636 -16 -870 72 -220 227 -399 439
       -509 89 -45 98 -52 108 -88 31 -101 152 -241 269 -311 141 -83 410 -189 682
       -267 85 -24 158 -50 163 -57 4 -6 8 -27 8 -45 0 -58 31 -172 54 -200 21 -24
       24 -41 29 -162 7 -150 24 -238 56 -281 16 -21 21 -43 21 -85 0 -76 28 -260 46
       -303 8 -19 21 -42 28 -51 8 -8 16 -52 19 -102 l6 -87 -57 -32 c-147 -83 -305
       -217 -345 -294 -29 -56 -29 -155 1 -212 31 -60 135 -161 224 -217 70 -44 77
       -52 83 -88 4 -21 21 -58 39 -81 64 -84 184 -126 382 -134 179 -7 338 23 417
       79 42 30 86 96 93 139 6 33 14 43 53 65 109 64 214 163 256 244 27 50 25 152
       -2 205 -40 77 -198 211 -344 294 l-57 32 6 86 c4 68 10 94 28 120 33 49 63
       208 64 335 0 37 5 70 10 73 37 23 70 182 70 341 0 78 3 93 20 109 24 22 46 91
       59 189 5 37 12 70 14 72 2 3 47 16 98 30 232 61 574 190 715 269 134 75 242
       193 293 317 21 51 25 55 114 100 208 106 365 288 436 507 76 233 70 566 -16
       870 -30 107 -48 131 -96 131 -53 0 -66 -17 -93 -125 -52 -213 -106 -336 -182
       -417 l-47 -51 -6 29 c-12 54 -9 209 6 277 17 81 56 171 102 235 37 52 41 86
       17 121 -15 20 -23 23 -68 19 -60 -5 -187 -58 -271 -113 -33 -21 -94 -73 -136
       -115 -130 -131 -199 -305 -199 -502 0 -46 4 -109 10 -140 8 -52 6 -64 -16
       -112 -49 -108 -49 -268 0 -399 l25 -66 -66 -21 c-96 -31 -156 -64 -373 -205
       l-195 -126 -6 148 c-3 81 -14 187 -23 236 -39 203 -127 402 -242 545 l-66 83
       42 54 c109 141 277 273 469 370 97 48 309 125 348 125 35 0 76 42 76 78 0 75
       -52 88 -193 49 -181 -50 -389 -148 -537 -254 -107 -76 -251 -221 -319 -321 -2
       -2 -24 11 -50 29 -133 91 -255 91 -388 0 -26 -18 -48 -31 -50 -29 -68 100
       -212 245 -319 321 -148 106 -356 204 -537 254 -141 39 -193 26 -193 -49 0 -36
       41 -78 76 -78 39 0 251 -77 348 -125 192 -97 360 -229 469 -370 l41 -53 -66
       -84 c-164 -207 -251 -462 -264 -776 l-6 -153 -195 126 c-217 141 -277 174
       -372 205 l-65 21 23 63 c49 135 49 273 1 396 -22 56 -24 72 -17 115 14 79 11
       219 -5 303 -9 46 -34 115 -61 171 -75 154 -216 281 -399 360 -44 19 -103 37
       -131 39 -45 4 -53 1 -68 -19z m355 -302 c106 -106 157 -222 166 -380 l6 -97
       -44 19 c-24 11 -83 25 -131 32 l-87 13 7 30 c4 17 7 99 6 181 -1 116 -5 166
       -20 222 l-20 72 29 -17 c16 -9 56 -43 88 -75z m3356 21 c-16 -57 -20 -107 -21
       -223 -1 -82 2 -164 6 -181 l7 -30 -87 -13 c-48 -7 -106 -21 -129 -31 l-43 -19
       0 68 c0 141 59 293 150 389 38 40 125 112 135 112 1 0 -7 -33 -18 -72z m-3854
       -279 c95 -196 197 -270 426 -309 149 -25 192 -45 231 -107 44 -70 53 -106 48
       -202 -6 -101 -33 -175 -103 -281 l-46 -69 -87 31 c-157 55 -246 111 -344 217
       -96 103 -160 238 -187 395 -18 98 -11 427 8 425 3 0 28 -45 54 -100z m4321 40
       c9 -95 7 -299 -5 -365 -27 -157 -91 -292 -187 -395 -98 -106 -187 -162 -344
       -217 l-87 -31 -46 69 c-70 106 -97 180 -103 281 -5 96 4 132 48 202 39 62 82
       82 231 107 227 37 338 118 429 314 25 52 48 95 51 96 3 0 9 -28 13 -61z
       m-2082 -249 c93 -62 210 -184 264 -275 119 -204 169 -431 162 -735 l-3 -152
       -58 -54 c-195 -181 -556 -220 -825 -88 -40 19 -98 59 -130 89 l-57 53 -3 152
       c-8 316 50 559 178 759 58 90 169 201 259 260 88 57 118 56 213 -9z m-1431
       -821 c88 -28 148 -62 412 -235 249 -161 267 -179 267 -264 l0 -42 -32 7 c-67
       14 -320 96 -434 140 -216 83 -321 141 -396 216 -65 64 -86 98 -53 85 27 -10
       62 11 101 63 22 28 46 50 55 51 9 0 45 -9 80 -21z m2773 -30 c39 -52 74 -73
       101 -63 33 13 12 -21 -52 -85 -76 -75 -181 -133 -397 -216 -114 -44 -367 -126
       -433 -140 l-33 -7 0 42 c0 86 17 103 287 277 277 179 291 187 383 219 91 32
       99 31 144 -27z m-1744 -641 c95 -33 95 -33 290 -33 195 0 195 0 290 33 52 18
       119 47 149 64 l54 30 -6 -94 c-3 -51 -11 -116 -17 -144 -10 -47 -16 -56 -70
       -93 -249 -175 -551 -175 -800 0 -54 37 -60 46 -70 93 -6 28 -14 93 -17 144
       l-6 94 54 -30 c30 -17 97 -46 149 -64z m-10 -465 c190 -73 418 -71 616 6 43
       17 80 28 82 26 7 -7 -18 -186 -28 -205 -14 -27 -111 -88 -187 -117 -60 -23
       -82 -27 -183 -27 -101 0 -123 4 -183 27 -75 29 -173 90 -186 116 -9 16 -31
       154 -31 193 0 15 4 18 18 13 9 -4 46 -18 82 -32z m90 -441 c67 -23 90 -26 210
       -26 125 0 141 2 225 31 50 17 91 30 93 29 4 -4 -28 -178 -35 -189 -8 -13 -88
       -47 -148 -63 -65 -18 -206 -17 -273 0 -29 8 -74 25 -100 38 -51 25 -57 40 -75
       172 -6 43 -5 47 11 41 9 -3 51 -18 92 -33z m55 -367 c78 -19 233 -19 314 0 35
       9 66 14 68 11 2 -2 -1 -32 -8 -66 -11 -59 -14 -62 -58 -85 -102 -51 -220 -51
       -322 0 -43 23 -47 27 -58 81 -13 68 -13 74 -3 74 4 -1 34 -7 67 -15z m620
       -320 c75 -54 164 -141 176 -173 7 -17 4 -33 -7 -58 -17 -36 -112 -128 -161
       -156 l-30 -17 -38 97 c-37 96 -116 258 -153 317 l-19 30 41 26 41 27 45 -25
       c25 -14 72 -45 105 -68z m-735 64 l38 -22 -23 -37 c-39 -62 -111 -210 -150
       -312 l-38 -97 -30 17 c-49 28 -144 120 -161 156 -21 44 -12 68 45 125 82 82
       232 189 268 190 7 1 30 -9 51 -20z m402 -152 c65 -114 84 -154 127 -266 76
       -194 77 -237 11 -271 -169 -86 -561 -55 -586 47 -16 61 69 287 184 490 l46 82
       86 0 86 0 46 -82z"/>
            <path d="M2263 3785 c-33 -33 -35 -39 -30 -81 7 -67 42 -99 107 -99 65 0 100
       32 107 99 5 42 3 48 -30 81 -29 29 -42 35 -77 35 -35 0 -48 -6 -77 -35z"/>
            <path d="M2703 3785 c-33 -33 -35 -39 -30 -81 7 -67 42 -99 107 -99 65 0 100
       32 107 99 5 42 3 48 -30 81 -29 29 -42 35 -77 35 -35 0 -48 -6 -77 -35z"/>
          </g>
        </svg>
      );
    case 'customizable_layout':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M3693 5099 c-48 -24 -109 -87 -128 -133 -7 -17 -118 -486 -246 -1043
              l-233 -1012 -896 -3 -895 -3 -56 -23 c-80 -32 -142 -90 -181 -170 l-33 -67 -3
              -1298 -2 -1298 24 -24 c25 -25 28 -25 194 -25 167 0 169 0 192 25 24 25 25 35
              94 980 l70 955 756 0 755 0 283 -958 c202 -682 289 -963 304 -979 21 -22 28
              -23 190 -23 166 0 169 0 193 25 15 14 25 36 25 52 0 16 -114 543 -254 1172
              l-254 1143 234 1210 c137 711 234 1235 234 1268 0 101 -53 184 -145 226 -67
              32 -162 33 -222 3z m162 -184 c17 -16 25 -35 24 -57 0 -18 -102 -559 -227
              -1203 l-227 -1170 -1114 -3 -1113 -2 4 69 c3 56 9 75 31 105 59 76 -18 70
              1034 76 927 5 942 5 957 25 9 11 18 27 20 35 3 8 113 485 246 1060 195 844
              245 1049 262 1067 29 31 72 30 103 -2z m-2419 -2667 c-4 -29 -38 -483 -76
              -1008 -38 -525 -72 -979 -76 -1007 l-5 -53 -40 0 -39 0 0 1060 0 1060 121 0
              120 0 -5 -52z m1587 -12 c9 -36 20 -72 23 -80 6 -14 -63 -16 -715 -16 l-721 0
              0 28 c0 15 3 51 6 80 l7 52 691 0 692 0 17 -64z m638 -996 l236 -1060 -38 0
              c-36 0 -39 3 -49 38 -5 20 -146 497 -313 1060 l-303 1022 116 0 116 0 235
              -1060z"/>
          </g>
        </svg>
      );
    case 'dedicated_event_coordinator':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2381 5104 c-137 -37 -277 -150 -341 -276 l-35 -68 -185 0 c-290 0
          -300 -7 -300 -191 l0 -119 -267 0 c-148 0 -288 -5 -313 -11 -167 -38 -314
          -176 -373 -348 l-22 -66 -3 -1775 c-1 -1100 1 -1801 7 -1844 12 -88 65 -197
          123 -253 57 -55 148 -109 227 -133 62 -20 101 -20 1661 -20 1560 0 1599 0
          1661 20 79 24 170 78 227 133 58 56 111 165 123 253 6 43 8 744 7 1844 l-3
          1775 -22 66 c-58 169 -195 301 -358 344 -46 12 -119 15 -327 15 l-268 0 0 119
          c0 185 -9 191 -306 191 l-191 0 -33 68 c-66 134 -197 236 -355 277 -75 19
          -260 19 -334 -1z m295 -243 c96 -43 169 -128 199 -233 11 -41 26 -66 51 -87
          l35 -31 194 0 195 0 0 -240 0 -240 -790 0 -790 0 0 240 0 240 183 0 c215 0
          233 6 262 87 37 101 55 133 96 178 97 105 243 140 365 86z m-1156 -826 c0
          -193 7 -215 73 -246 40 -18 80 -19 957 -19 573 0 928 4 951 10 19 5 48 24 65
          41 l29 30 3 175 3 175 262 -3 c252 -3 264 -4 307 -26 58 -31 86 -57 118 -112
          l27 -45 2 -1800 3 -1800 -23 -40 c-25 -44 -99 -99 -155 -115 -51 -14 -3113
          -14 -3164 0 -56 16 -130 71 -155 115 l-23 40 0 1788 c0 1583 2 1792 15 1824
          32 76 92 133 170 161 18 6 129 11 283 11 l252 1 0 -165z"/>
            <path d="M1892 3358 c-31 -29 -123 -117 -206 -195 -82 -79 -152 -143 -156
          -143 -3 0 -48 43 -99 95 -101 102 -130 117 -194 96 -44 -15 -67 -40 -77 -86
          -16 -71 1 -98 164 -262 151 -153 151 -153 196 -153 25 1 56 7 68 14 34 18 506
          466 526 498 21 33 21 92 0 128 -24 39 -65 60 -119 60 -42 0 -51 -5 -103 -52z"/>
            <path d="M2354 3077 c-64 -58 -55 -152 19 -197 31 -19 56 -20 768 -20 l736 0
          34 23 c69 46 73 147 8 201 l-31 26 -749 0 -749 0 -36 -33z"/>
            <path d="M1946 2425 c-15 -8 -115 -99 -222 -201 l-193 -186 -82 87 c-92 97
          -120 115 -178 115 -75 0 -129 -75 -111 -155 6 -27 41 -70 145 -178 173 -179
          207 -199 278 -162 32 17 533 490 541 512 3 8 6 34 6 57 0 92 -102 153 -184
          111z"/>
            <path d="M2383 2125 c-86 -37 -94 -168 -15 -222 25 -17 70 -18 769 -18 734 0
          742 0 770 21 83 61 75 183 -14 220 -48 21 -1463 20 -1510 -1z"/>
            <path d="M1819 1338 c-71 -68 -166 -156 -210 -197 l-80 -75 -98 98 c-108 107
          -137 121 -203 93 -47 -20 -70 -55 -70 -110 -1 -62 19 -91 180 -250 133 -131
          140 -137 180 -137 22 0 51 4 64 9 26 10 503 461 530 500 22 33 23 94 2 131
          -24 39 -65 60 -118 60 -46 0 -49 -2 -177 -122z"/>
            <path d="M2354 1127 c-64 -58 -55 -152 19 -197 31 -19 56 -20 768 -20 l736 0
          34 23 c69 46 73 147 8 201 l-31 26 -749 0 -749 0 -36 -33z"/>
          </g>
        </svg>
      );
    case 'dedicated_parking':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2486 3664 c-216 -33 -276 -58 -852 -352 l-451 -230 -324 -47 c-178
            -25 -351 -53 -383 -61 -178 -45 -337 -178 -417 -349 -53 -113 -59 -156 -59
            -440 0 -251 1 -262 21 -284 11 -12 32 -26 46 -32 13 -5 127 -9 253 -9 l228 0
            17 -52 c55 -170 211 -311 387 -353 271 -64 555 90 643 348 l19 57 932 0 932 0
            13 -37 c26 -83 74 -160 138 -224 168 -167 418 -206 633 -98 114 58 228 189
            265 305 l17 54 242 0 c134 0 254 4 267 9 14 6 35 20 46 32 21 22 21 30 21 443
            0 463 -2 476 -62 595 -41 81 -134 176 -213 218 -104 54 -1106 451 -1205 477
            -52 14 -133 30 -180 36 -128 15 -864 11 -974 -6z m984 -370 c69 -90 131 -173
            139 -184 13 -20 11 -20 -403 -20 l-416 0 0 185 0 185 278 -1 277 0 125 -165z
            m-900 -24 l0 -180 -437 0 -438 0 85 46 c134 73 437 223 493 246 70 27 224 67
            265 68 l32 0 0 -180z m1490 -30 c195 -78 357 -144 359 -146 2 -2 -112 -4 -255
            -4 l-259 0 -105 137 c-122 159 -128 168 -109 161 8 -3 174 -70 369 -148z m452
            -483 c4 -100 8 -121 33 -173 56 -114 150 -180 287 -198 l78 -10 0 -148 0 -148
            -182 2 -182 3 -12 46 c-33 132 -152 278 -274 337 -71 35 -180 62 -250 62 -71
            0 -175 -26 -248 -62 -127 -62 -259 -228 -278 -350 l-7 -38 -930 2 -931 3 -11
            45 c-48 186 -216 344 -410 385 -127 27 -243 12 -363 -47 -128 -63 -259 -227
            -278 -350 l-7 -38 -168 0 -169 0 0 104 0 103 63 7 c240 28 433 194 503 436
            l22 75 213 31 214 32 1641 1 1641 1 5 -113z m348 81 c26 -43 39 -88 46 -170
            l7 -68 -34 0 c-47 0 -99 29 -126 69 -19 29 -23 47 -23 117 l0 84 55 0 c52 0
            57 -2 75 -32z m-4311 -95 c-39 -104 -155 -200 -272 -227 l-28 -7 21 41 c44 86
            137 166 233 201 29 10 53 18 54 19 1 0 -2 -12 -8 -27z m665 -466 c222 -102
            255 -407 59 -552 -171 -126 -419 -54 -493 142 -29 77 -25 187 8 255 79 160
            266 228 426 155z m2941 -6 c168 -83 228 -282 136 -445 -127 -227 -456 -215
            -572 19 -20 42 -24 65 -24 140 0 106 19 155 87 224 104 105 240 127 373 62z"/>
            <path d="M2129 2551 c-58 -58 -31 -158 49 -181 49 -13 705 -13 754 0 47 13 78
            52 78 97 0 41 -23 89 -49 103 -13 6 -162 10 -411 10 l-392 0 -29 -29z"/>
            <path d="M1025 2116 c-50 -22 -77 -60 -83 -114 -6 -65 17 -112 67 -140 68 -39
            155 -19 192 42 30 49 26 126 -8 167 -46 54 -109 71 -168 45z"/>
            <path d="M3950 2112 c-101 -50 -108 -196 -11 -250 101 -57 211 7 211 121 0 63
            -23 102 -75 127 -51 25 -80 25 -125 2z"/>
          </g>
        </svg>
      );
    case 'digital_payments':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2405 5101 c-80 -37 -125 -111 -125 -206 l0 -55 -127 0 c-147 0 -192
            -15 -245 -81 l-31 -39 -372 0 -371 0 -52 -26 c-29 -14 -67 -44 -86 -66 -71
            -83 -66 100 -66 -2268 0 -2368 -5 -2185 66 -2268 19 -22 57 -52 86 -66 l52
            -26 1426 0 1426 0 52 26 c29 14 67 44 86 66 59 68 66 106 66 333 0 198 0 202
            -23 223 -34 32 -89 30 -116 -4 -20 -25 -21 -40 -21 -213 0 -167 -2 -189 -20
            -218 -10 -17 -29 -38 -42 -45 -34 -19 -2782 -19 -2816 0 -13 7 -32 28 -42 45
            -20 31 -20 70 -20 2145 0 1855 2 2117 15 2142 31 61 26 60 407 60 l348 0 0
            -55 0 -55 -308 0 -309 0 -21 -23 c-21 -22 -22 -32 -22 -219 0 -177 2 -198 19
            -219 27 -33 95 -33 122 0 17 20 19 41 19 162 l0 139 250 0 250 0 13 -32 c21
            -51 63 -94 112 -117 43 -20 59 -21 410 -21 l365 0 0 -290 0 -290 -145 0 c-189
            0 -238 -19 -286 -112 -18 -35 -19 -68 -19 -695 l0 -658 23 -40 c12 -21 42 -53
            65 -70 44 -30 44 -30 203 -33 l159 -3 0 -729 0 -729 -32 14 c-18 7 -86 36
            -151 64 -65 28 -127 51 -137 51 -10 0 -96 -36 -191 -80 -95 -44 -179 -80 -186
            -80 -8 0 -93 36 -190 80 -97 44 -185 80 -197 80 -12 0 -86 -29 -166 -65 l-145
            -64 -5 1631 -5 1630 -24 19 c-32 26 -77 24 -106 -6 l-25 -24 0 -1704 0 -1704
            23 -21 c14 -13 36 -22 55 -22 18 0 113 35 215 80 l184 81 177 -81 c99 -44 191
            -80 208 -80 18 0 107 35 206 81 l175 80 186 -81 c196 -85 237 -94 271 -60 20
            20 20 33 20 830 l0 810 418 0 c385 0 420 2 457 19 22 10 52 31 67 47 59 64 58
            45 58 742 l0 638 -25 51 c-16 32 -41 61 -67 79 l-41 29 -434 3 -433 3 0 289 0
            290 205 0 c225 0 244 4 307 59 56 49 71 96 76 249 l4 132 222 0 c242 0 251 -2
            281 -60 13 -25 15 -249 15 -1803 l0 -1774 21 -27 c29 -37 89 -37 118 0 l21 27
            0 1787 c0 1980 5 1836 -66 1918 -19 22 -57 52 -86 66 -52 26 -53 26 -302 26
            l-249 0 -34 39 c-56 66 -100 81 -240 81 l-120 0 -7 62 c-8 70 -31 121 -73 159
            -61 57 -72 59 -330 59 -210 0 -243 -3 -278 -19z m497 -159 c20 -20 23 -34 26
            -122 5 -138 -1 -134 213 -140 144 -4 171 -8 186 -23 16 -16 18 -38 18 -178 0
            -157 -1 -161 -24 -180 -22 -18 -48 -19 -642 -19 -606 0 -619 0 -639 20 -18 18
            -20 33 -20 178 0 121 3 163 14 178 13 17 31 19 185 24 216 6 213 4 219 147 4
            103 14 128 56 136 11 3 103 4 203 4 176 -2 183 -3 205 -25z m840 -1579 c17
            -15 18 -48 18 -626 0 -554 -2 -612 -17 -629 -15 -17 -48 -18 -625 -18 -401 0
            -616 4 -629 10 -18 10 -19 28 -19 633 0 471 3 626 12 635 9 9 162 12 627 12
            558 0 616 -2 633 -17z"/>
            <path d="M2760 3247 c-32 -16 -47 -59 -34 -95 6 -15 77 -116 157 -223 l147
            -196 0 -181 0 -182 -64 0 c-58 0 -67 -3 -85 -26 -12 -15 -21 -37 -21 -49 0
            -12 9 -34 21 -49 l20 -26 210 0 c196 0 210 1 229 20 12 12 20 33 20 55 0 56
            -27 75 -105 75 l-65 0 0 178 0 178 155 208 c85 114 156 215 156 224 3 40 -4
            58 -27 80 -25 22 -27 22 -357 22 -246 0 -338 -4 -357 -13z m494 -159 c-8 -14
            -122 -167 -134 -181 -5 -5 -42 37 -84 92 l-77 101 151 0 c118 0 150 -3 144
            -12z"/>
            <path d="M1514 3936 c-49 -22 -59 -74 -23 -120 l20 -26 295 0 c281 0 295 1
            314 20 21 21 26 69 10 100 -18 34 -65 40 -329 40 -192 -1 -264 -4 -287 -14z"/>
            <path d="M2360 3943 c-53 -21 -69 -94 -30 -133 18 -18 33 -20 150 -20 117 0
            132 2 150 20 26 26 26 80 1 111 -18 22 -27 24 -138 26 -65 1 -125 0 -133 -4z"/>
            <path d="M1491 3514 c-39 -49 -18 -114 41 -129 13 -3 146 -5 296 -3 l274 3 19
            24 c26 32 25 82 -4 109 -23 22 -29 22 -314 22 l-292 0 -20 -26z"/>
            <path d="M1495 3115 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25 286
            0 286 0 24 25 c35 34 34 86 -1 114 -26 20 -39 21 -311 21 l-284 0 -24 -25z"/>
            <path d="M1513 2730 c-45 -19 -56 -80 -22 -124 l20 -26 293 0 293 0 21 23 c27
            29 28 77 3 108 l-19 24 -284 2 c-155 1 -293 -2 -305 -7z"/>
            <path d="M1490 2308 c-11 -12 -20 -35 -20 -53 0 -18 9 -41 20 -53 20 -22 25
            -22 315 -22 282 0 296 1 315 20 12 12 20 33 20 55 0 22 -8 43 -20 55 -19 19
            -33 20 -315 20 -290 0 -295 0 -315 -22z"/>
            <path d="M1491 1904 c-26 -33 -27 -75 -2 -105 l19 -24 297 0 297 0 19 24 c26
            32 25 82 -4 109 -23 22 -29 22 -314 22 l-292 0 -20 -26z"/>
            <path d="M2007 1620 c-13 -11 -28 -35 -31 -54 -5 -25 -14 -37 -37 -46 -16 -7
            -45 -30 -63 -51 -82 -96 -71 -235 25 -311 20 -16 78 -47 130 -68 136 -56 160
            -101 89 -171 -24 -24 -37 -29 -80 -29 -31 0 -66 8 -90 20 -56 29 -104 26 -129
            -6 -12 -15 -21 -38 -21 -52 0 -35 60 -89 118 -105 39 -12 49 -19 58 -47 13
            -40 47 -70 79 -70 38 0 75 37 75 74 0 29 5 35 40 51 48 21 105 82 128 134 8
            20 15 64 15 101 1 116 -54 184 -186 235 -93 35 -147 70 -147 94 0 66 95 89
            161 38 67 -51 143 -25 143 48 0 32 -7 44 -43 76 -23 21 -58 43 -77 49 -28 10
            -34 16 -34 40 0 33 -40 70 -75 70 -12 0 -34 -9 -48 -20z"/>
          </g>
        </svg>
      );
    case 'go_to_for_charity_galas':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2468 5095 c-34 -34 -38 -60 -38 -247 0 -171 9 -212 51 -242 38 -27
          96 -21 130 13 l29 29 0 205 0 205 -26 31 c-22 27 -32 31 -74 31 -37 0 -53 -5
          -72 -25z"/>
            <path d="M2041 5086 c-31 -17 -50 -53 -51 -92 0 -28 42 -107 140 -261 33 -51
          116 -59 161 -14 47 48 41 86 -37 219 -36 61 -79 123 -96 137 -33 28 -79 32
          -117 11z"/>
            <path d="M2950 5086 c-14 -8 -57 -66 -97 -134 -60 -98 -73 -127 -73 -161 0
          -84 97 -133 161 -82 39 30 160 237 166 281 5 35 2 43 -30 75 -39 39 -81 46
          -127 21z"/>
            <path d="M1323 4730 c-26 -15 -49 -52 -108 -170 -230 -460 -402 -987 -460
          -1415 -22 -159 -23 -454 -1 -595 30 -198 88 -374 168 -508 48 -80 157 -192
          227 -232 33 -19 61 -41 61 -47 0 -18 -201 -1097 -205 -1102 -3 -2 -102 16
          -222 40 -238 47 -318 52 -373 25 -95 -49 -150 -144 -150 -262 0 -104 45 -180
          133 -226 49 -25 1137 -238 1216 -238 110 0 205 70 236 172 33 113 13 218 -56
          287 -50 51 -93 65 -341 112 -184 35 -228 46 -228 59 0 9 45 258 100 554 55
          296 100 542 100 547 0 5 17 9 38 9 93 0 227 55 342 141 255 189 476 578 578
          1019 26 110 67 372 84 530 17 164 17 817 0 952 -14 116 -25 141 -66 162 -26
          13 -988 206 -1028 206 -7 0 -28 -9 -45 -20z m524 -287 l402 -78 8 -65 c4 -36
          8 -220 8 -410 -1 -264 -5 -379 -18 -490 -19 -157 -60 -402 -77 -462 l-11 -38
          -604 0 -605 0 0 49 c0 236 118 714 275 1112 57 145 203 459 214 459 3 1 187
          -34 408 -77z m243 -1762 c0 -18 -62 -157 -109 -244 -165 -306 -378 -487 -572
          -487 -224 0 -403 272 -453 688 l-7 52 571 0 c313 0 570 -4 570 -9z m-1007
          -2256 c297 -57 545 -109 553 -116 31 -25 9 -99 -29 -99 -39 0 -1112 212 -1124
          222 -18 15 -16 68 3 90 10 10 25 16 38 13 12 -3 263 -52 559 -110z"/>
            <path d="M1977 4176 c-14 -13 -28 -39 -31 -57 -3 -19 -7 -181 -10 -361 l-5
          -326 25 -25 c34 -34 73 -42 118 -23 65 27 68 47 74 411 4 284 3 325 -12 353
          -20 39 -43 52 -94 52 -27 0 -47 -7 -65 -24z"/>
            <path d="M1943 3178 c-93 -25 -99 -156 -8 -194 66 -27 145 24 145 96 0 21 -33
          90 -42 90 -2 0 -17 4 -33 8 -17 5 -44 5 -62 0z"/>
            <path d="M3215 4651 c-269 -54 -498 -101 -507 -105 -37 -17 -50 -57 -64 -200
          -19 -194 -18 -678 1 -887 42 -448 118 -762 256 -1049 87 -181 174 -306 298
          -430 148 -147 286 -224 426 -237 l61 -6 107 -551 c59 -303 107 -556 107 -562
          0 -5 -102 -30 -227 -53 -126 -24 -242 -49 -259 -56 -47 -19 -115 -84 -135
          -130 -24 -51 -25 -155 -2 -220 21 -63 95 -139 149 -154 22 -6 62 -11 90 -11
          76 0 1163 214 1211 239 46 23 100 80 119 127 8 19 14 60 14 92 0 170 -104 287
          -255 286 -27 0 -148 -20 -268 -43 -120 -24 -220 -42 -222 -40 -2 3 -53 254
          -112 559 l-109 555 55 30 c78 41 186 154 247 257 59 100 124 284 151 428 14
          76 18 148 18 355 0 226 -3 276 -22 383 -80 437 -259 966 -461 1356 -37 71 -77
          135 -88 143 -12 8 -37 16 -55 18 -19 2 -254 -40 -524 -94z m528 -268 c161
          -339 288 -704 362 -1045 14 -65 25 -122 25 -128 0 -7 -206 -10 -620 -10 l-620
          0 -5 23 c-9 42 -24 182 -36 331 -12 150 -7 750 6 791 6 19 48 29 393 97 213
          42 396 76 408 77 17 1 32 -23 87 -136z m414 -1585 c-10 -349 -86 -594 -230
          -741 -77 -78 -132 -102 -238 -102 -79 0 -91 3 -165 39 -182 90 -380 355 -501
          670 -34 91 -93 287 -93 313 0 11 111 13 616 13 l617 0 -6 -192z m483 -2289
          c14 -25 12 -65 -2 -77 -13 -10 -1086 -222 -1125 -222 -38 0 -60 74 -29 99 17
          14 1115 230 1134 223 7 -2 17 -13 22 -23z"/>
            <path d="M3493 4360 c-54 -32 -61 -116 -14 -163 10 -11 39 -21 63 -24 37 -4
          49 -1 71 18 75 65 33 189 -63 189 -14 0 -39 -9 -57 -20z"/>
            <path d="M3648 3995 c-64 -36 -63 -70 13 -308 72 -227 75 -235 111 -253 55
          -29 120 -5 144 52 13 30 13 42 -2 97 -33 132 -116 369 -137 393 -28 32 -90 41
          -129 19z"/>
          </g>
        </svg>
      );
    case 'go_to_for_networking_events':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M257 4859 c-93 -22 -184 -95 -228 -184 l-24 -50 -3 -632 c-2 -607 -2
       -637 18 -735 87 -426 409 -782 821 -905 l89 -27 0 -778 0 -778 -282 0 c-276 0
       -284 -1 -334 -24 -61 -28 -117 -92 -134 -150 -7 -26 -11 -84 -8 -142 4 -116
       20 -149 91 -183 43 -21 52 -21 934 -21 l890 0 44 23 c70 37 89 80 89 202 0 84
       -4 108 -24 150 -28 60 -66 97 -126 124 -42 19 -65 21 -327 21 l-283 0 0 778 0
       779 78 22 c241 70 489 247 631 451 106 152 187 349 211 515 8 55 10 272 8 695
       l-3 615 -25 50 c-33 67 -99 131 -168 163 l-57 27 -920 2 c-517 1 -936 -3 -958
       -8z m1860 -190 c70 -34 87 -68 91 -179 l4 -95 -94 -61 c-185 -122 -336 -191
       -477 -219 -154 -32 -265 -8 -540 114 -244 109 -293 123 -416 123 -138 0 -342
       -45 -488 -107 -17 -7 -18 1 -15 166 3 165 4 176 27 208 13 18 42 42 65 52 39
       18 84 19 920 19 872 0 880 0 923 -21z m-1282 -527 c38 -13 123 -49 188 -79
       244 -112 340 -137 512 -137 110 1 143 5 216 27 112 34 241 91 359 159 l95 55
       3 -389 c2 -348 0 -399 -17 -481 -109 -533 -633 -895 -1162 -802 -418 73 -746
       390 -830 802 -17 81 -19 131 -17 418 l3 327 69 29 c147 63 374 112 466 101 25
       -3 76 -16 115 -30z m445 -2607 l0 -765 -85 0 -85 0 0 765 0 765 85 0 85 0 0
       -765z m735 -970 c21 -20 25 -34 25 -80 l0 -55 -845 0 -845 0 0 55 c0 46 4 60
       25 80 l24 25 796 0 796 0 24 -25z"/>
            <path d="M535 3905 c-56 -55 -18 -155 59 -155 53 0 91 37 91 90 0 34 -6 47
       -28 66 -38 32 -90 32 -122 -1z"/>
            <path d="M792 3650 c-48 -45 -35 -121 25 -147 39 -16 68 -10 101 20 31 29 31
       95 -1 125 -31 29 -95 30 -125 2z"/>
            <path d="M470 3407 c-53 -27 -64 -89 -24 -136 54 -65 154 -28 154 58 0 32 -6
       44 -31 65 -34 29 -61 32 -99 13z"/>
            <path d="M705 3135 c-38 -37 -35 -95 7 -130 56 -47 132 -18 144 56 14 82 -92
       133 -151 74z"/>
            <path d="M2987 4859 c-93 -22 -183 -95 -227 -184 l-25 -50 -3 -624 c-3 -678
       -1 -710 54 -875 121 -370 430 -671 797 -777 l77 -22 0 -779 0 -778 -282 0
       c-263 0 -286 -2 -328 -21 -60 -27 -98 -64 -126 -124 -20 -42 -24 -66 -24 -150
       0 -122 19 -165 89 -202 l44 -23 890 0 c882 0 891 0 934 21 72 35 87 67 91 188
       3 80 0 113 -13 148 -24 62 -70 112 -130 139 -49 23 -58 24 -332 24 l-283 0 0
       778 0 779 78 22 c42 13 119 42 169 66 348 164 596 487 668 869 13 69 15 178
       13 711 l-3 630 -24 50 c-33 66 -100 131 -169 163 l-57 27 -920 2 c-517 1 -936
       -3 -958 -8z m1859 -188 c23 -10 52 -34 65 -52 23 -32 24 -43 27 -208 3 -165 2
       -173 -15 -166 -139 59 -349 106 -478 108 -120 1 -179 -16 -412 -118 -294 -129
       -399 -152 -554 -120 -140 28 -287 95 -477 219 l-94 61 4 95 c4 111 20 144 90
       179 43 21 48 21 923 21 837 0 882 -1 921 -19z m-258 -517 c87 -15 225 -58 305
       -94 l47 -21 0 -327 c0 -281 -3 -340 -19 -418 -73 -351 -332 -643 -676 -761
       -569 -194 -1195 169 -1316 764 -17 82 -19 133 -17 481 l3 389 95 -55 c266
       -153 497 -214 690 -182 110 18 215 53 400 135 278 122 289 124 488 89z m-578
       -2619 l0 -765 -85 0 -85 0 0 765 0 765 85 0 85 0 0 -765z m735 -970 c21 -20
       25 -34 25 -80 l0 -55 -845 0 -845 0 0 55 c0 46 4 60 25 80 l24 25 796 0 796 0
       24 -25z"/>
            <path d="M4460 3820 c-45 -45 -32 -122 25 -146 90 -37 164 63 104 140 -17 21
       -29 26 -65 26 -30 0 -50 -6 -64 -20z"/>
            <path d="M4225 3579 c-33 -19 -45 -41 -45 -81 0 -79 86 -116 147 -64 35 30 39
       95 7 126 -24 25 -82 35 -109 19z"/>
            <path d="M4545 3305 c-38 -37 -35 -95 7 -130 26 -22 39 -26 66 -21 44 7 70 31
       78 71 6 35 -8 71 -35 92 -28 22 -89 16 -116 -12z"/>
            <path d="M4320 3073 c-66 -26 -78 -117 -21 -160 9 -7 33 -13 54 -13 109 -1
       120 157 12 174 -17 3 -37 3 -45 -1z"/>
          </g>
        </svg>
      );
    case 'nearby_accommodations':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2330 5108 c-320 -53 -569 -162 -807 -355 -298 -241 -500 -573 -585
      -963 -20 -93 -23 -132 -22 -335 0 -216 2 -237 28 -350 49 -210 136 -413 254
      -588 103 -152 493 -769 489 -773 -2 -2 -60 -15 -128 -28 -182 -36 -248 -52
      -412 -102 -401 -122 -666 -287 -779 -484 -47 -83 -61 -143 -56 -249 3 -85 8
      -105 39 -171 74 -155 208 -273 434 -385 337 -166 804 -274 1360 -315 180 -13
      652 -13 826 0 868 66 1520 293 1746 609 67 92 93 174 93 285 0 137 -48 243
      -161 356 -199 199 -575 357 -1088 456 -68 13 -126 26 -128 28 -2 2 121 199
      273 437 153 239 299 475 325 524 62 119 111 253 145 400 26 113 28 134 28 350
      1 203 -2 242 -22 335 -152 697 -682 1204 -1374 1314 -108 18 -385 20 -478 4z
      m447 -313 c542 -88 982 -514 1100 -1065 22 -106 25 -430 4 -525 -26 -117 -82
      -271 -134 -367 -52 -98 -1178 -1867 -1187 -1867 -3 0 -225 347 -495 771 -269
      423 -530 832 -578 906 -198 305 -267 514 -267 817 0 472 238 897 645 1153 125
      78 321 151 475 177 105 18 327 18 437 0z m-612 -3789 c155 -243 293 -449 308
      -460 40 -29 134 -29 174 0 15 11 152 214 305 455 153 239 284 438 291 443 18
      12 289 -33 473 -79 339 -83 598 -205 722 -339 76 -82 83 -147 25 -222 -42 -56
      -58 -71 -133 -123 -257 -179 -784 -318 -1365 -361 -180 -13 -630 -13 -810 0
      -726 53 -1331 250 -1502 488 -54 74 -45 138 29 218 157 170 518 313 1003 398
      77 14 153 24 170 23 27 -2 49 -33 310 -441z"/>
            <path d="M2370 4190 c-41 -11 -113 -39 -160 -62 -69 -34 -103 -60 -180 -138
      -78 -77 -104 -111 -138 -180 -59 -118 -82 -218 -82 -350 0 -132 23 -232 82
      -350 34 -69 60 -103 138 -180 77 -78 111 -104 180 -138 118 -59 218 -82 350
      -82 132 0 232 23 350 82 69 34 103 60 180 138 78 77 104 111 138 180 59 118
      82 218 82 350 0 132 -23 232 -82 350 -34 69 -60 103 -138 180 -77 78 -111 104
      -180 138 -47 23 -121 51 -164 62 -106 27 -272 26 -376 0z m353 -319 c108 -43
      205 -139 249 -249 33 -82 33 -242 0 -325 -44 -109 -140 -205 -250 -249 -82
      -33 -242 -33 -324 0 -110 44 -206 141 -249 249 -31 79 -37 212 -14 285 49 147
      137 241 277 294 84 32 223 30 311 -5z"/>
          </g>
        </svg>
      );
    case 'outdoor_dining':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2400 5105 c-46 -11 -2213 -940 -2320 -994 -54 -27 -80 -73 -80 -141
              0 -67 26 -113 80 -141 33 -18 85 -19 1217 -19 l1183 0 0 -675 0 -675 -657 0
              c-625 0 -660 -1 -700 -19 -30 -14 -48 -31 -62 -60 -19 -37 -21 -58 -21 -271 0
              -213 2 -234 21 -271 14 -29 32 -46 62 -60 38 -17 68 -19 320 -19 l277 0 0
              -805 0 -805 -260 0 -260 0 0 384 0 383 54 6 c98 10 188 76 229 166 32 70 29
              180 -5 246 -33 63 -79 108 -143 137 -50 23 -53 23 -542 26 l-493 3 0 634 0
              634 -26 20 c-15 12 -37 21 -49 21 -12 0 -34 -9 -49 -21 l-26 -20 0 -1310 0
              -1309 -58 0 c-44 0 -63 -5 -75 -18 -23 -26 -22 -94 1 -115 17 -16 208 -17
              2544 -17 2433 0 2526 1 2541 18 10 11 17 35 17 59 0 56 -22 73 -93 73 l-57 0
              0 1309 0 1310 -26 20 c-15 12 -37 21 -49 21 -12 0 -34 -9 -49 -21 l-26 -20 0
              -634 0 -634 -492 -3 c-490 -3 -493 -3 -543 -26 -64 -29 -110 -74 -143 -137
              -21 -41 -26 -63 -26 -125 0 -83 16 -129 64 -190 40 -50 138 -100 197 -100 l43
              0 0 -385 0 -385 -260 0 -260 0 0 805 0 805 278 0 c251 0 281 2 319 19 30 14
              48 31 62 60 19 37 21 58 21 273 0 270 -4 286 -80 325 l-44 23 -658 0 -658 0 0
              675 0 675 631 0 c384 0 637 4 648 10 30 15 46 65 32 99 -19 45 -46 51 -234 51
              l-172 0 -256 413 c-141 226 -255 413 -253 415 1 2 433 -183 959 -410 l957
              -413 -380 -5 c-356 -5 -381 -6 -401 -24 -30 -27 -28 -85 2 -114 23 -22 26 -22
              428 -22 374 0 407 1 439 19 54 28 80 74 80 141 0 68 -26 114 -80 141 -115 59
              -2274 983 -2324 994 -75 18 -242 18 -316 0z m306 -165 l60 -20 289 -467 c160
              -256 292 -470 293 -475 2 -4 -353 -8 -788 -8 -435 0 -790 4 -788 8 2 5 134
              219 293 476 l290 467 60 19 c84 26 212 26 291 0z m-833 -487 c-119 -192 -235
              -378 -257 -415 l-41 -68 -705 1 c-458 0 -698 4 -685 10 46 22 1891 818 1897
              818 4 1 -90 -155 -209 -346z m2047 -2343 l0 -190 -1360 0 -1360 0 0 190 0 190
              1360 0 1360 0 0 -190z m-1900 -715 l0 -366 25 -24 c31 -32 80 -33 112 -3 l23
              21 0 369 0 368 150 0 150 0 0 -805 0 -805 -150 0 -150 0 0 295 0 296 -26 26
              c-21 21 -32 24 -62 20 -68 -12 -67 -5 -70 -337 l-3 -300 -69 0 -70 0 0 805 0
              805 70 0 70 0 0 -365z m920 -440 l0 -805 -150 0 -150 0 0 805 0 805 150 0 150
              0 0 -805z m300 0 l0 -805 -70 0 -70 0 0 805 0 805 70 0 70 0 0 -805z m-1988
              375 c106 -30 127 -172 33 -230 -31 -19 -49 -20 -509 -20 l-476 0 0 130 0 130
              458 0 c281 0 471 -4 494 -10z m3568 -120 l0 -130 -476 0 c-460 0 -478 1 -509
              20 -43 26 -69 81 -61 126 9 44 50 92 89 103 18 5 240 10 495 10 l462 1 0 -130z
              m-3770 -450 l0 -160 -375 0 -375 0 0 160 0 160 375 0 375 0 0 -160z m3770 0
              l0 -160 -375 0 -375 0 0 160 0 160 375 0 375 0 0 -160z m-3770 -465 l0 -145
              -375 0 -375 0 0 145 0 145 375 0 375 0 0 -145z m3770 0 l0 -145 -375 0 -375 0
              0 145 0 145 375 0 375 0 0 -145z"/>
          </g>
        </svg>
      );
    case 'popular_for_corporate_luncheons':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2504 4699 c-47 -13 -101 -79 -109 -131 -3 -24 -5 -245 -3 -493 3
          -499 2 -488 70 -537 26 -18 46 -23 98 -23 52 0 72 5 98 23 68 49 67 38 70 537
          2 248 0 469 -3 493 -11 76 -93 144 -170 141 -11 0 -34 -5 -51 -10z"/>
            <path d="M785 3990 c-71 -35 -105 -114 -84 -194 9 -35 57 -88 337 -369 180
          -181 336 -333 347 -339 63 -35 141 -27 196 19 59 50 74 125 39 197 -27 57
          -642 670 -692 691 -50 20 -95 19 -143 -5z"/>
            <path d="M4185 3990 c-56 -28 -669 -643 -690 -692 -62 -149 98 -289 240 -210
          11 6 167 158 347 339 280 281 328 334 337 369 21 80 -14 159 -85 194 -51 25
          -98 25 -149 0z"/>
            <path d="M2459 3349 c-613 -48 -1102 -538 -1147 -1149 -19 -258 37 -512 162
          -728 l45 -77 -553 -5 -553 -5 -35 -27 c-49 -37 -71 -89 -66 -150 6 -55 28 -92
          78 -125 l33 -23 2137 0 2137 0 33 22 c58 40 75 72 75 142 0 53 -4 68 -27 98
          -53 70 -35 68 -638 68 l-542 0 28 48 c125 212 185 423 185 655 0 300 -84 547
          -266 782 -208 268 -538 448 -861 471 -44 3 -90 6 -104 8 -14 1 -68 -1 -121 -5z
          m326 -358 c88 -23 220 -83 293 -132 68 -47 178 -155 228 -225 57 -80 119 -210
          146 -309 28 -105 31 -324 5 -429 -41 -165 -114 -299 -231 -423 l-78 -83 -588
          0 -587 0 -69 73 c-116 122 -193 258 -235 412 -18 65 -22 108 -22 225 -1 169
          17 250 83 389 130 271 365 454 666 516 97 20 285 13 389 -14z"/>
            <path d="M95 2296 c-37 -16 -82 -67 -91 -102 -11 -42 2 -120 24 -151 46 -64
          40 -64 579 -61 l488 3 33 23 c43 31 64 75 64 132 0 72 -41 135 -105 159 -45
          17 -953 14 -992 -3z"/>
            <path d="M4030 2299 c-61 -25 -102 -89 -102 -159 0 -57 21 -101 64 -132 l33
          -23 488 -3 c436 -2 491 -1 523 14 52 25 78 68 82 139 3 50 0 65 -20 94 -12 19
          -36 44 -51 55 -28 21 -39 21 -510 23 -307 1 -491 -2 -507 -8z"/>
            <path d="M1735 741 c-82 -20 -130 -90 -123 -176 5 -65 37 -111 92 -136 39 -18
          83 -19 856 -19 773 0 817 1 856 19 116 53 126 224 17 292 l-38 24 -815 2
          c-448 1 -829 -2 -845 -6z"/>
          </g>
        </svg>
      );
    case 'popular_for_team_nights_out':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M347 4098 c-77 -83 -138 -198 -168 -318 -19 -77 -17 -259 4 -340 28
       -106 89 -219 162 -298 l65 -70 -143 -4 c-137 -3 -145 -4 -185 -31 -76 -50 -82
       -75 -82 -319 l1 -213 98 -425 c54 -234 101 -432 105 -441 6 -14 -2 -17 -48
       -21 -39 -3 -65 -12 -90 -32 -52 -39 -66 -73 -66 -159 0 -202 140 -379 345
       -433 75 -21 563 -20 592 1 44 31 37 111 -13 134 -16 7 -109 11 -269 11 -275 0
       -313 7 -393 75 -52 45 -102 143 -102 202 l0 43 2400 0 2400 0 0 -43 c0 -59
       -50 -157 -102 -202 -23 -19 -66 -44 -95 -55 -53 -20 -76 -20 -1804 -20 l-1750
       0 -24 -25 c-33 -32 -34 -83 -2 -113 l23 -22 1760 0 c1437 0 1768 3 1809 14
       205 55 345 232 345 437 0 82 -15 116 -66 155 -25 20 -51 29 -90 32 -46 4 -54
       7 -48 21 4 9 51 207 105 441 l98 425 1 213 c0 194 -2 216 -20 252 -32 62 -86
       92 -175 98 l-75 5 36 39 c30 34 35 45 30 72 -5 24 -107 132 -469 494 -440 439
       -464 462 -499 462 -31 0 -43 -8 -90 -58 -237 -257 -229 -685 19 -953 l55 -59
       -124 0 c-171 0 -228 -23 -268 -105 -18 -37 -20 -62 -20 -243 l0 -201 99 -433
       c54 -238 102 -441 106 -450 7 -17 -11 -18 -284 -18 -160 0 -291 2 -291 4 0 3
       4 16 10 30 5 14 52 214 105 444 l95 418 0 210 0 210 -29 45 c-36 57 -85 82
       -174 87 l-69 4 30 32 c39 41 44 61 28 99 -8 18 -213 231 -468 485 -433 432
       -455 452 -490 452 -30 0 -44 -8 -74 -37 -123 -127 -188 -295 -187 -488 0 -187
       60 -345 179 -474 l64 -69 -137 -4 c-164 -4 -200 -16 -244 -87 l-29 -45 0 -210
       0 -210 95 -418 c53 -230 100 -430 105 -444 6 -14 10 -27 10 -30 0 -2 -131 -4
       -291 -4 -273 0 -291 1 -284 17 4 10 52 213 106 451 l99 433 0 201 c0 181 -2
       206 -20 243 -34 70 -100 105 -198 105 l-56 0 32 33 c25 26 32 42 32 72 0 39
       -9 49 -463 502 -451 452 -463 463 -501 463 -33 0 -45 -7 -79 -42z m223 -293
       c90 -90 129 -136 119 -139 -20 -7 -339 -66 -357 -66 -34 0 6 184 64 290 15 28
       30 50 33 50 4 0 67 -61 141 -135z m1882 -136 c-7 -6 -334 -69 -360 -69 -11 0
       -12 13 -7 63 10 86 38 173 75 231 l32 49 134 -134 c73 -73 130 -136 126 -140z
       m1760 0 c-8 -7 -329 -69 -357 -69 -37 0 11 206 69 299 l28 44 134 -134 c73
       -73 130 -136 126 -140z m-3450 -186 c-22 -124 -72 -376 -75 -379 -2 -2 -33 10
       -68 28 -87 43 -196 152 -237 238 -27 57 -29 65 -14 69 66 15 352 69 373 70 23
       1 26 -2 21 -26z m1764 10 c-2 -10 -19 -94 -36 -188 -17 -93 -34 -178 -38 -188
       -12 -31 -123 32 -207 117 -81 82 -147 198 -117 205 68 16 352 69 375 70 23 1
       27 -3 23 -16z m1761 -2 c-2 -10 -19 -99 -37 -197 -19 -99 -36 -182 -40 -187
       -14 -16 -135 58 -200 122 -60 60 -143 191 -130 205 7 6 349 73 384 75 23 1 27
       -2 23 -18z m-3097 -313 c0 -12 -84 -57 -146 -79 -54 -19 -194 -38 -194 -27 0
       7 46 255 61 327 l11 54 134 -134 c74 -74 134 -137 134 -141z m1760 0 c0 -12
       -84 -57 -146 -79 -32 -11 -89 -23 -127 -26 l-70 -6 6 24 c3 13 17 87 32 164
       14 77 28 153 31 169 6 28 9 26 140 -105 74 -74 134 -137 134 -141z m1757 -3
       c-2 -5 -30 -24 -63 -42 -64 -35 -169 -63 -234 -63 -35 0 -41 3 -37 18 3 9 20
       95 38 190 l32 174 134 -134 c73 -73 132 -137 130 -143z m-3279 -277 c8 -8 12
       -54 12 -140 l0 -128 -646 0 -646 0 4 130 c2 103 6 133 18 140 24 15 1243 13
       1258 -2z m1756 6 c14 -5 16 -27 16 -140 l0 -134 -640 0 -640 0 0 134 c0 98 3
       136 13 139 18 8 1232 8 1251 1z m1766 -4 c12 -7 16 -37 18 -139 l4 -130 -376
       -3 c-373 -3 -376 -3 -398 -25 -28 -28 -29 -84 -3 -113 18 -19 28 -20 382 -20
       258 0 363 -3 363 -11 0 -31 -164 -729 -178 -759 -11 -23 -32 -43 -62 -57 -43
       -23 -51 -23 -380 -23 -309 0 -338 2 -377 20 -32 14 -48 29 -63 62 -15 33 -180
       731 -180 762 0 3 54 6 120 6 107 0 122 2 140 20 21 21 26 69 10 100 -15 29
       -65 40 -181 40 l-109 0 0 128 c0 86 4 132 12 140 15 15 1234 17 1258 2z
       m-3530 -436 c0 -31 -165 -729 -180 -762 -15 -33 -31 -48 -63 -62 -39 -18 -68
       -20 -377 -20 -329 0 -337 0 -380 23 -30 14 -51 34 -62 57 -14 30 -178 728
       -178 759 0 8 174 11 620 11 341 0 620 -3 620 -6z m1760 -3 c0 -4 -38 -176 -85
       -380 -57 -249 -91 -382 -105 -401 -40 -56 -67 -60 -430 -60 -363 0 -390 4
       -430 60 -14 19 -48 152 -105 401 -47 204 -85 376 -85 380 0 5 279 9 620 9 341
       0 620 -4 620 -9z"/>
          </g>
        </svg>
      );
    case 'rooftop_access':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2095 5095 c-24 -23 -25 -28 -25 -170 l0 -145 -196 0 c-197 0 -224
            -4 -251 -39 -9 -11 -12 -75 -13 -218 l0 -202 -150 -3 c-140 -3 -151 -4 -172
            -26 l-23 -23 -3 -379 -3 -380 -373 0 c-363 0 -374 -1 -400 -21 l-26 -20 0
            -1644 0 -1643 -89 -4 c-78 -3 -93 -6 -115 -27 -35 -33 -36 -98 -3 -129 l23
            -22 2285 0 2286 0 21 23 c32 34 30 96 -4 128 -22 21 -37 24 -115 27 l-89 4 0
            2274 c0 2251 0 2274 -20 2294 -11 11 -30 22 -42 25 -13 3 -395 4 -850 3 l-827
            -3 -23 -23 c-22 -22 -23 -30 -26 -227 l-3 -205 -79 0 -79 0 -3 205 c-3 197 -4
            205 -26 227 -22 22 -30 23 -227 26 l-205 3 0 135 c0 172 -14 203 -93 204 -27
            0 -45 -7 -62 -25z m435 -635 l0 -140 -370 0 -370 0 0 140 0 140 370 0 370 0 0
            -140z m1950 -2070 l0 -2210 -310 0 -310 0 0 1171 c0 870 -3 1176 -12 1192 -18
            34 -39 46 -81 47 -48 0 -87 -35 -87 -80 l0 -30 -85 0 -85 0 0 30 c0 45 -39 80
            -88 80 -46 0 -78 -29 -88 -78 l-6 -32 -139 0 -139 0 0 1060 0 1060 715 0 715
            0 0 -2210z m-1610 920 l0 -830 -310 0 -310 0 0 140 0 140 145 0 c142 0 147 1
            170 25 18 17 25 35 25 62 -1 79 -32 93 -204 93 l-136 0 0 140 0 140 145 0
            c142 0 147 1 170 25 18 17 25 35 25 62 -1 79 -33 93 -203 93 l-135 0 -6 35
            c-4 19 -17 44 -28 55 -21 19 -36 20 -400 20 l-378 0 0 315 0 315 715 0 715 0
            0 -830z m-800 -1555 l0 -1575 -200 0 -200 0 0 203 0 204 -26 24 c-25 24 -27
            24 -274 27 -274 3 -311 -3 -330 -53 -6 -15 -10 -112 -10 -216 l0 -189 -195 0
            -195 0 0 1575 0 1575 715 0 715 0 0 -1575z m1610 -515 l0 -1060 -715 0 -715 0
            0 1060 0 1060 715 0 715 0 0 -1060z m-2190 -920 l0 -140 -25 0 c-25 0 -25 1
            -25 88 0 79 -2 91 -22 109 -34 30 -62 36 -102 19 -45 -19 -56 -46 -56 -142 0
            -72 -1 -74 -25 -74 l-25 0 0 140 0 140 140 0 140 0 0 -140z"/>
            <path d="M3380 4307 c-37 -18 -50 -54 -50 -135 0 -85 16 -124 59 -141 34 -15
            67 -7 99 22 20 19 22 29 22 116 -1 100 -7 118 -47 139 -28 15 -52 15 -83 -1z"/>
            <path d="M3725 4308 c-39 -23 -44 -41 -45 -139 0 -87 2 -97 23 -117 34 -32 76
            -37 114 -13 38 24 47 59 41 161 -3 54 -8 75 -24 90 -25 25 -82 35 -109 18z"/>
            <path d="M4070 4307 c-37 -18 -50 -54 -50 -135 0 -85 16 -124 59 -142 35 -14
            85 -2 107 27 18 24 18 201 1 224 -29 37 -74 48 -117 26z"/>
            <path d="M3362 3720 c-26 -25 -36 -72 -30 -155 3 -50 9 -70 28 -90 32 -34 91
            -35 125 0 23 22 25 32 25 120 0 88 -2 98 -25 120 -31 32 -91 34 -123 5z"/>
            <path d="M3705 3715 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 17 -18 35
            -25 62 -25 70 1 93 37 93 145 0 108 -23 144 -93 145 -27 0 -45 -7 -62 -25z"/>
            <path d="M4049 3714 c-22 -23 -24 -34 -24 -119 0 -85 2 -96 24 -119 33 -35 92
            -36 126 -1 23 22 25 32 25 120 0 88 -2 98 -25 120 -34 35 -93 34 -126 -1z"/>
            <path d="M3383 3160 c-35 -14 -53 -61 -53 -140 0 -83 16 -122 59 -140 35 -14
            85 -2 107 27 18 24 18 201 1 224 -27 35 -71 46 -114 29z"/>
            <path d="M3740 3163 c-8 -3 -25 -15 -37 -26 -21 -19 -23 -30 -23 -117 0 -91 2
            -98 26 -121 34 -32 95 -33 126 -1 21 20 23 32 23 122 0 86 -3 102 -20 119 -21
            22 -70 34 -95 24z"/>
            <path d="M4071 3156 c-37 -21 -50 -55 -51 -134 0 -85 16 -124 59 -142 35 -14
            85 -2 107 27 18 24 18 201 1 224 -29 38 -75 48 -116 25z"/>
            <path d="M4049 2564 c-22 -23 -24 -34 -24 -119 0 -85 2 -96 24 -119 33 -35 92
            -36 126 -1 23 22 25 32 25 120 0 88 -2 98 -25 120 -34 35 -93 34 -126 -1z"/>
            <path d="M4071 2006 c-38 -21 -50 -55 -51 -136 0 -57 5 -83 20 -107 32 -52
            108 -54 147 -4 17 22 17 200 -1 223 -13 17 -57 38 -79 38 -7 -1 -23 -7 -36
            -14z"/>
            <path d="M4049 1414 c-22 -23 -24 -34 -24 -119 0 -85 2 -96 24 -119 33 -35 92
            -36 126 -1 23 22 25 32 25 120 0 88 -2 98 -25 120 -34 35 -93 34 -126 -1z"/>
            <path d="M4074 856 c-42 -19 -57 -66 -52 -161 4 -84 20 -111 73 -121 35 -6 71
            8 92 35 8 10 12 52 13 112 0 86 -2 98 -22 117 -31 28 -67 34 -104 18z"/>
            <path d="M1770 3847 c-53 -27 -64 -89 -24 -136 l26 -31 384 0 385 0 24 25 c18
            17 25 35 25 62 -1 42 -13 63 -47 81 -32 18 -739 16 -773 -1z"/>
            <path d="M965 2928 c-38 -22 -44 -41 -44 -138 0 -105 7 -123 58 -141 41 -15
            87 3 106 42 18 34 20 157 3 194 -21 46 -81 67 -123 43z"/>
            <path d="M1310 2928 c-41 -22 -51 -54 -48 -150 3 -75 6 -91 26 -110 31 -32 92
            -31 126 1 24 23 26 30 26 121 0 88 -2 98 -23 118 -29 28 -75 36 -107 20z"/>
            <path d="M1655 2928 c-38 -22 -44 -41 -44 -138 0 -105 7 -123 58 -141 41 -15
            87 3 106 42 20 38 20 161 0 200 -23 44 -78 61 -120 37z"/>
            <path d="M945 2335 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 34 -35 93
            -34 126 1 22 23 24 34 24 119 0 85 -2 96 -24 119 -33 35 -92 36 -126 1z"/>
            <path d="M1299 2347 c-29 -22 -39 -55 -39 -132 0 -108 23 -144 93 -145 27 0
            45 7 62 25 23 22 25 32 25 120 0 88 -2 98 -25 120 -27 28 -88 34 -116 12z"/>
            <path d="M1635 2335 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 34 -35 93
            -34 126 1 22 23 24 34 24 119 0 85 -2 96 -24 119 -33 35 -92 36 -126 1z"/>
            <path d="M971 1779 c-13 -5 -30 -17 -37 -27 -18 -23 -18 -201 -1 -223 39 -50
            115 -48 147 4 15 24 20 50 20 105 0 83 -15 122 -55 140 -30 14 -41 14 -74 1z"/>
            <path d="M1310 1778 c-41 -22 -51 -54 -48 -149 2 -68 7 -91 22 -108 37 -40
            109 -36 143 8 8 10 12 52 13 112 0 87 -2 97 -23 117 -29 28 -75 36 -107 20z"/>
            <path d="M1661 1779 c-13 -5 -30 -17 -37 -27 -18 -23 -18 -201 -1 -223 34 -44
            106 -48 143 -8 14 16 20 40 23 100 4 93 -9 133 -50 154 -32 17 -44 18 -78 4z"/>
            <path d="M945 1185 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 17 -18 35
            -25 63 -25 72 0 98 50 90 175 -3 50 -9 70 -28 90 -32 34 -91 35 -125 0z"/>
            <path d="M1299 1197 c-29 -22 -39 -55 -39 -132 0 -108 23 -144 93 -145 27 0
            45 7 62 25 23 22 25 32 25 120 0 88 -2 98 -25 120 -27 28 -88 34 -116 12z"/>
            <path d="M1635 1185 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 17 -18 35
            -25 63 -25 72 0 98 50 90 175 -3 50 -9 70 -28 90 -32 34 -91 35 -125 0z"/>
            <path d="M2555 2105 l-25 -24 0 -843 c1 -647 3 -847 13 -859 34 -44 93 -50
            134 -15 l28 24 0 845 0 845 -24 26 c-33 35 -92 36 -126 1z"/>
            <path d="M2899 2104 l-24 -26 0 -843 c0 -812 1 -844 19 -864 37 -40 109 -36
            143 8 10 12 12 212 13 859 l0 843 -25 24 c-34 35 -93 34 -126 -1z"/>
            <path d="M3245 2105 l-25 -24 0 -843 c1 -647 3 -847 13 -859 34 -44 93 -50
            134 -15 l28 24 3 829 c1 455 0 838 -3 850 -15 63 -104 85 -150 38z"/>
          </g>
        </svg>
      );
    case 'sommelier_on_site':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2600 5103 c-87 -60 -1222 -1004 -1296 -1077 -240 -240 -393 -550
          -440 -891 l-7 -50 -14 45 c-8 25 -29 102 -48 173 -41 151 -59 188 -117 243
          -67 65 -119 87 -218 92 -70 3 -95 0 -142 -18 -71 -26 -145 -92 -183 -162 -57
          -104 -43 -282 50 -664 164 -668 479 -1262 938 -1766 l89 -98 -93 -160 c-71
          -125 -96 -160 -108 -155 -9 3 -124 68 -256 144 -297 171 -276 161 -320 161
          -85 0 -127 -105 -67 -169 15 -17 309 -192 653 -391 546 -315 630 -360 667
          -360 55 0 92 31 98 85 9 71 -8 86 -302 254 -148 85 -270 155 -272 157 -3 1
          149 274 162 291 1 1 31 -21 66 -49 87 -67 246 -173 348 -232 119 -68 224 -115
          714 -322 452 -191 488 -202 535 -169 13 9 28 33 34 55 14 46 0 90 -36 114 -12
          8 -222 99 -466 202 -467 196 -622 269 -766 358 -183 113 -405 295 -531 435
          -490 543 -815 1216 -941 1945 -12 65 -21 143 -21 173 0 51 3 59 39 94 35 35
          44 39 90 39 44 0 56 -5 86 -32 31 -29 39 -49 69 -163 140 -530 344 -937 687
          -1374 114 -144 634 -670 697 -704 85 -46 203 -52 295 -14 52 22 125 93 155
          152 37 73 42 179 12 260 -21 56 -44 82 -376 415 -362 364 -394 402 -394 475 0
          38 31 104 61 132 32 29 90 53 129 53 60 0 103 -30 263 -187 353 -344 642 -534
          1107 -728 454 -190 720 -360 1024 -655 141 -137 209 -216 430 -500 92 -118
          177 -220 189 -227 49 -27 123 -6 145 42 21 45 14 81 -25 132 -335 442 -448
          574 -635 749 -192 181 -447 366 -635 463 -94 49 -92 40 -23 122 167 197 275
          408 333 649 14 58 83 453 154 878 l128 772 -20 36 c-18 32 -114 90 -807 490
          -843 486 -827 479 -888 437z m232 -304 c43 -24 78 -46 77 -49 0 -3 -118 -102
          -263 -220 -145 -118 -271 -227 -280 -242 -50 -82 23 -178 117 -154 13 3 160
          118 326 255 l303 249 47 -27 c25 -15 244 -141 486 -281 242 -139 441 -254 442
          -254 2 -2 -154 -959 -162 -992 -9 -42 -204 -125 -369 -159 -106 -21 -323 -22
          -443 -1 -192 34 -357 92 -593 211 -242 121 -429 186 -638 220 -81 14 -143 16
          -285 12 -193 -5 -296 -24 -436 -81 l-59 -24 15 52 c60 206 192 424 345 572 35
          34 321 274 637 533 l573 472 42 -24 c22 -13 76 -43 118 -68z m-893 -1669 c143
          -32 284 -86 521 -200 236 -113 377 -165 549 -200 275 -56 573 -38 800 47 35
          13 65 22 67 21 8 -8 -50 -283 -76 -360 -60 -179 -161 -348 -294 -493 l-76 -83
          -129 53 c-264 109 -459 216 -661 363 -143 105 -219 170 -396 343 -166 162
          -212 192 -323 211 -318 54 -558 -276 -415 -571 22 -45 87 -116 333 -364 342
          -345 337 -334 93 -217 -511 245 -811 640 -863 1137 -6 60 -9 126 -7 147 3 38
          6 42 73 78 131 71 267 107 435 117 133 7 248 -2 369 -29z"/>
            <path d="M2153 4108 c-74 -19 -98 -114 -44 -169 24 -23 38 -29 74 -29 94 0
          137 107 71 174 -29 28 -58 35 -101 24z"/>
          </g>
        </svg>
      );
    case 'vip_executive_rooms':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M980 4060 c-14 -14 -20 -33 -20 -61 0 -34 36 -274 56 -371 l6 -28
          -211 0 c-198 0 -212 -1 -231 -20 -19 -19 -20 -33 -20 -231 l0 -211 -27 6 c-98
          20 -338 56 -372 56 -28 0 -47 -6 -61 -20 -20 -20 -20 -33 -20 -620 0 -587 0
          -600 20 -620 14 -14 33 -20 61 -20 34 0 274 36 372 56 l27 6 0 -211 c0 -198 1
          -212 20 -231 19 -19 33 -20 231 -20 l211 0 -6 -28 c-20 -97 -56 -337 -56 -371
          0 -28 6 -47 20 -61 20 -20 33 -20 620 -20 587 0 600 0 620 20 14 14 20 33 20
          61 0 34 -36 274 -56 371 l-6 28 382 0 382 0 -6 -28 c-20 -97 -56 -337 -56
          -371 0 -28 6 -47 20 -61 20 -20 33 -20 620 -20 587 0 600 0 620 20 14 14 20
          33 20 61 0 34 -36 274 -56 371 l-6 28 211 0 c198 0 212 1 231 20 19 19 20 33
          20 231 l0 211 28 -6 c97 -20 337 -56 371 -56 28 0 47 6 61 20 20 20 20 33 20
          620 0 587 0 600 -20 620 -14 14 -33 20 -61 20 -34 0 -274 -36 -371 -56 l-28
          -6 0 211 c0 198 -1 212 -20 231 -19 19 -33 20 -231 20 l-211 0 6 28 c20 97 56
          337 56 371 0 28 -6 47 -20 61 -20 20 -33 20 -620 20 -587 0 -600 0 -620 -20
          -14 -14 -20 -33 -20 -61 0 -34 36 -274 56 -371 l6 -28 -382 0 -382 0 6 28 c20
          97 56 337 56 371 0 28 -6 47 -20 61 -20 20 -33 20 -620 20 -587 0 -600 0 -620
          -20z m1077 -162 c-3 -13 -15 -85 -28 -160 l-22 -138 -407 0 -407 0 -22 138
          c-13 75 -25 147 -28 160 l-5 22 462 0 462 0 -5 -22z m1920 0 c-3 -13 -15 -85
          -28 -160 l-22 -138 -407 0 -407 0 -22 138 c-13 75 -25 147 -28 160 l-5 22 462
          0 462 0 -5 -22z m423 -1338 l0 -880 -1840 0 -1840 0 0 880 0 880 1840 0 1840
          0 0 -880z m-3977 429 l137 -22 0 -407 0 -407 -137 -22 c-76 -13 -148 -25 -160
          -28 l-23 -5 0 462 0 462 23 -5 c12 -3 84 -15 160 -28z m4457 -430 l0 -461 -22
          5 c-13 3 -85 15 -160 28 l-138 22 0 407 0 407 148 25 c81 14 153 26 160 27 9
          1 12 -97 12 -460z m-2851 -1176 c13 -76 25 -148 28 -160 l5 -23 -462 0 -462 0
          5 23 c3 12 15 84 28 160 l22 137 407 0 407 0 22 -137z m1920 0 c13 -76 25
          -148 28 -160 l5 -23 -462 0 -462 0 5 23 c3 12 15 84 28 160 l22 137 407 0 407
          0 22 -137z"/>
          </g>
        </svg>
      );
    case 'waterfront_view':
      return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="35" height="35" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2095 5095 c-24 -23 -25 -28 -25 -170 l0 -145 -196 0 c-197 0 -224
          -4 -251 -39 -9 -11 -12 -75 -13 -218 l0 -202 -150 -3 c-140 -3 -151 -4 -172
          -26 l-23 -23 -3 -379 -3 -380 -373 0 c-363 0 -374 -1 -400 -21 l-26 -20 0
          -1644 0 -1643 -89 -4 c-78 -3 -93 -6 -115 -27 -35 -33 -36 -98 -3 -129 l23
          -22 2285 0 2286 0 21 23 c32 34 30 96 -4 128 -22 21 -37 24 -115 27 l-89 4 0
          2274 c0 2251 0 2274 -20 2294 -11 11 -30 22 -42 25 -13 3 -395 4 -850 3 l-827
          -3 -23 -23 c-22 -22 -23 -30 -26 -227 l-3 -205 -79 0 -79 0 -3 205 c-3 197 -4
          205 -26 227 -22 22 -30 23 -227 26 l-205 3 0 135 c0 172 -14 203 -93 204 -27
          0 -45 -7 -62 -25z m435 -635 l0 -140 -370 0 -370 0 0 140 0 140 370 0 370 0 0
          -140z m1950 -2070 l0 -2210 -310 0 -310 0 0 1171 c0 870 -3 1176 -12 1192 -18
          34 -39 46 -81 47 -48 0 -87 -35 -87 -80 l0 -30 -85 0 -85 0 0 30 c0 45 -39 80
          -88 80 -46 0 -78 -29 -88 -78 l-6 -32 -139 0 -139 0 0 1060 0 1060 715 0 715
          0 0 -2210z m-1610 920 l0 -830 -310 0 -310 0 0 140 0 140 145 0 c142 0 147 1
          170 25 18 17 25 35 25 62 -1 79 -32 93 -204 93 l-136 0 0 140 0 140 145 0
          c142 0 147 1 170 25 18 17 25 35 25 62 -1 79 -33 93 -203 93 l-135 0 -6 35
          c-4 19 -17 44 -28 55 -21 19 -36 20 -400 20 l-378 0 0 315 0 315 715 0 715 0
          0 -830z m-800 -1555 l0 -1575 -200 0 -200 0 0 203 0 204 -26 24 c-25 24 -27
          24 -274 27 -274 3 -311 -3 -330 -53 -6 -15 -10 -112 -10 -216 l0 -189 -195 0
          -195 0 0 1575 0 1575 715 0 715 0 0 -1575z m1610 -515 l0 -1060 -715 0 -715 0
          0 1060 0 1060 715 0 715 0 0 -1060z m-2190 -920 l0 -140 -25 0 c-25 0 -25 1
          -25 88 0 79 -2 91 -22 109 -34 30 -62 36 -102 19 -45 -19 -56 -46 -56 -142 0
          -72 -1 -74 -25 -74 l-25 0 0 140 0 140 140 0 140 0 0 -140z"/>
            <path d="M3380 4307 c-37 -18 -50 -54 -50 -135 0 -85 16 -124 59 -141 34 -15
          67 -7 99 22 20 19 22 29 22 116 -1 100 -7 118 -47 139 -28 15 -52 15 -83 -1z"/>
            <path d="M3725 4308 c-39 -23 -44 -41 -45 -139 0 -87 2 -97 23 -117 34 -32 76
          -37 114 -13 38 24 47 59 41 161 -3 54 -8 75 -24 90 -25 25 -82 35 -109 18z"/>
            <path d="M4070 4307 c-37 -18 -50 -54 -50 -135 0 -85 16 -124 59 -142 35 -14
          85 -2 107 27 18 24 18 201 1 224 -29 37 -74 48 -117 26z"/>
            <path d="M3362 3720 c-26 -25 -36 -72 -30 -155 3 -50 9 -70 28 -90 32 -34 91
          -35 125 0 23 22 25 32 25 120 0 88 -2 98 -25 120 -31 32 -91 34 -123 5z"/>
            <path d="M3705 3715 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 17 -18 35
          -25 62 -25 70 1 93 37 93 145 0 108 -23 144 -93 145 -27 0 -45 -7 -62 -25z"/>
            <path d="M4049 3714 c-22 -23 -24 -34 -24 -119 0 -85 2 -96 24 -119 33 -35 92
          -36 126 -1 23 22 25 32 25 120 0 88 -2 98 -25 120 -34 35 -93 34 -126 -1z"/>
            <path d="M3383 3160 c-35 -14 -53 -61 -53 -140 0 -83 16 -122 59 -140 35 -14
          85 -2 107 27 18 24 18 201 1 224 -27 35 -71 46 -114 29z"/>
            <path d="M3740 3163 c-8 -3 -25 -15 -37 -26 -21 -19 -23 -30 -23 -117 0 -91 2
          -98 26 -121 34 -32 95 -33 126 -1 21 20 23 32 23 122 0 86 -3 102 -20 119 -21
          22 -70 34 -95 24z"/>
            <path d="M4071 3156 c-37 -21 -50 -55 -51 -134 0 -85 16 -124 59 -142 35 -14
          85 -2 107 27 18 24 18 201 1 224 -29 38 -75 48 -116 25z"/>
            <path d="M4049 2564 c-22 -23 -24 -34 -24 -119 0 -85 2 -96 24 -119 33 -35 92
          -36 126 -1 23 22 25 32 25 120 0 88 -2 98 -25 120 -34 35 -93 34 -126 -1z"/>
            <path d="M4071 2006 c-38 -21 -50 -55 -51 -136 0 -57 5 -83 20 -107 32 -52
          108 -54 147 -4 17 22 17 200 -1 223 -13 17 -57 38 -79 38 -7 -1 -23 -7 -36
          -14z"/>
            <path d="M4049 1414 c-22 -23 -24 -34 -24 -119 0 -85 2 -96 24 -119 33 -35 92
          -36 126 -1 23 22 25 32 25 120 0 88 -2 98 -25 120 -34 35 -93 34 -126 -1z"/>
            <path d="M4074 856 c-42 -19 -57 -66 -52 -161 4 -84 20 -111 73 -121 35 -6 71
          8 92 35 8 10 12 52 13 112 0 86 -2 98 -22 117 -31 28 -67 34 -104 18z"/>
            <path d="M1770 3847 c-53 -27 -64 -89 -24 -136 l26 -31 384 0 385 0 24 25 c18
          17 25 35 25 62 -1 42 -13 63 -47 81 -32 18 -739 16 -773 -1z"/>
            <path d="M965 2928 c-38 -22 -44 -41 -44 -138 0 -105 7 -123 58 -141 41 -15
          87 3 106 42 18 34 20 157 3 194 -21 46 -81 67 -123 43z"/>
            <path d="M1310 2928 c-41 -22 -51 -54 -48 -150 3 -75 6 -91 26 -110 31 -32 92
          -31 126 1 24 23 26 30 26 121 0 88 -2 98 -23 118 -29 28 -75 36 -107 20z"/>
            <path d="M1655 2928 c-38 -22 -44 -41 -44 -138 0 -105 7 -123 58 -141 41 -15
          87 3 106 42 20 38 20 161 0 200 -23 44 -78 61 -120 37z"/>
            <path d="M945 2335 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 34 -35 93
          -34 126 1 22 23 24 34 24 119 0 85 -2 96 -24 119 -33 35 -92 36 -126 1z"/>
            <path d="M1299 2347 c-29 -22 -39 -55 -39 -132 0 -108 23 -144 93 -145 27 0
          45 7 62 25 23 22 25 32 25 120 0 88 -2 98 -25 120 -27 28 -88 34 -116 12z"/>
            <path d="M1635 2335 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 34 -35 93
          -34 126 1 22 23 24 34 24 119 0 85 -2 96 -24 119 -33 35 -92 36 -126 1z"/>
            <path d="M971 1779 c-13 -5 -30 -17 -37 -27 -18 -23 -18 -201 -1 -223 39 -50
          115 -48 147 4 15 24 20 50 20 105 0 83 -15 122 -55 140 -30 14 -41 14 -74 1z"/>
            <path d="M1310 1778 c-41 -22 -51 -54 -48 -149 2 -68 7 -91 22 -108 37 -40
          109 -36 143 8 8 10 12 52 13 112 0 87 -2 97 -23 117 -29 28 -75 36 -107 20z"/>
            <path d="M1661 1779 c-13 -5 -30 -17 -37 -27 -18 -23 -18 -201 -1 -223 34 -44
          106 -48 143 -8 14 16 20 40 23 100 4 93 -9 133 -50 154 -32 17 -44 18 -78 4z"/>
            <path d="M945 1185 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 17 -18 35
          -25 63 -25 72 0 98 50 90 175 -3 50 -9 70 -28 90 -32 34 -91 35 -125 0z"/>
            <path d="M1299 1197 c-29 -22 -39 -55 -39 -132 0 -108 23 -144 93 -145 27 0
          45 7 62 25 23 22 25 32 25 120 0 88 -2 98 -25 120 -27 28 -88 34 -116 12z"/>
            <path d="M1635 1185 c-23 -22 -25 -32 -25 -120 0 -88 2 -98 25 -120 17 -18 35
          -25 63 -25 72 0 98 50 90 175 -3 50 -9 70 -28 90 -32 34 -91 35 -125 0z"/>
            <path d="M2555 2105 l-25 -24 0 -843 c1 -647 3 -847 13 -859 34 -44 93 -50
          134 -15 l28 24 0 845 0 845 -24 26 c-33 35 -92 36 -126 1z"/>
            <path d="M2899 2104 l-24 -26 0 -843 c0 -812 1 -844 19 -864 37 -40 109 -36
          143 8 10 12 12 212 13 859 l0 843 -25 24 c-34 35 -93 34 -126 -1z"/>
            <path d="M3245 2105 l-25 -24 0 -843 c1 -647 3 -847 13 -859 34 -44 93 -50
          134 -15 l28 24 3 829 c1 455 0 838 -3 850 -15 63 -104 85 -150 38z"/>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

IconAmenities.defaultProps = {
  className: null,
  rootClassName: null,
  name: 'default',
};

IconAmenities.propTypes = {
  className: string,
  rootClassName: string,
  name: string,
};

export default IconAmenities;
