import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string, shape } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconCard,
  IconArrowHead,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { priceCatergory } from '../../../config/configListing';
import SearchComponent from '../../../containers/PageBuilder/SectionBuilder/SectionContainer/TopbarFilterForm';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import moment from 'moment';
import { useHistory, useLocation, Link } from 'react-router-dom';
import rightArrow from '../images/rightArrow.svg';

const TopbarDesktop = props => {
  const { pathname } = useLocation();

  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Event handler to toggle between dark and light modes
  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  useEffect(() => {
    setMounted(true);
  }, []);
  const navbarClass = isDarkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light';
  const buttonClass = isDarkMode ? 'btn btn-light' : 'btn btn-dark';
  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const { email, profile } = currentUser?.attributes || {};
  const { firstName, lastName } = profile || {};
  const buyout = priceCatergory[0].enumOptions;
  const classes = classNames(
    rootClassName || css.root,
    className,
    currentPage == 'landingPage' && css.largeTopbar
  );
  const handleSubmit = values => {
    const { buyout, date, keyword } = values;
    const pub_price_Trier = `${buyout}`;
    const end = date && moment(date?.endDate).format('YYYY-MM-DD');
    const start = date && moment(date?.startDate).format('YYYY-MM-DD');

    const SearchParams = {
      dates: start ? `${start},${end}` : null,
      pub_total_capacity: keyword ? `${keyword}` : null,
      pub_price_Trier: buyout?.length > 0 ? pub_price_Trier : null,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, SearchParams));
  };
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );
  const filter = (
    // <div className={css.searchBarBox}>
    //   <div className={css.dateInput}>
    //     <input
    //       type="date"
    //       name='date'
    //     />
    //   </div>
    //   <div className={css.capacityInput}>
    //     <input
    //       type="text"
    //       name='capacity'
    //       placeholder='Any Capacity'
    //     />
    //   </div>
    //   <div className={css.capacityInput}>
    //     <input
    //       type="text"
    //       name='space'
    //       placeholder='Any Space'
    //     />
    //   </div>
    //   <div className={css.searchButton}>
    //     <button>
    //       <IconCard brand="searchright" />
    //     </button>
    //   </div>
    // </div>
    <SearchComponent onSubmit={handleSubmit} />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders', type: 'upcoming' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.Link}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.Link}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <div className={css.profileListLinks}>
            <NamedLink name="ProfileSettingsPage" className={css.afterLoginProfileLinks}>
              <div className={css.leftSide}>
                <div>
                  <IconCard brand="grayprofile" />
                </div>
                <div className={css.otherLinksHeading}>
                  <div className={css.mainHeading}>
                    {firstName} {lastName}
                  </div>
                  <div className={css.subHeading}>{email}</div>
                </div>
              </div>
              <span className={css.editIcon}>
                <IconCard brand="editprofile" />
              </span>
            </NamedLink>
          </div>
          <div className={css.dividerLine} />
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          {currentUser && currentUser?.attributes?.profile?.publicData?.userRole === 'provider' ? (
            <div className={css.bottomProfileLinksBox}>
              <NamedLink className={css.profileOtherLinks} name="ManageListingsPage">
                <div>
                  <IconCard brand="listing" />
                </div>
                <div className={css.otherLinksHeading}>
                  <div className={css.mainHeading}>
                    <FormattedMessage id="TopbarDesktop.yourListingsLink" />
                  </div>
                </div>
              </NamedLink>
            </div>
          ) : (
            <React.Fragment />
          )}
        </MenuItem>
        <MenuItem key="InboxPage" className={css.secondLink}>
          <div className={css.bottomProfileLinks}>
            <NamedLink
              name="InboxPage"
              className={css.profileOtherLinks}
              params={{ tab: currentUserHasListings ? 'sales' : 'orders', type: 'upcoming' }}
            >
              <div>
                <IconCard brand="bookingicon" />
              </div>
              <div className={css.otherLinksHeading}>
                <div className={css.mainHeading}>My Bookings</div>
                <div className={css.subHeading}>Manage confirmed and pending bookings</div>
              </div>
            </NamedLink>
          </div>
        </MenuItem>
        <MenuItem key="help">
          <div className={css.bottomProfileLinks}>
            <NamedLink name="LoginPage" className={css.profileOtherLinks}>
              <div>
                <IconCard brand="helpcenter" />
              </div>
              <div className={css.otherLinksHeading}>
                <div className={css.mainHeading}>Help Centre</div>
                <div className={css.subHeading}>Need assistance? We're here</div>
              </div>
            </NamedLink>
          </div>
          <div className={css.dividerLine} />
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton
            rootClassName={css.logoutButton}
            onClick={onLogout}
            className={css.profileLogout}
          >
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>

        {/* <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  ) : null;

  const LoginMenu = !authenticatedOnClientSide ? (
    <div className={css.loginMenuContainer}>
      <NamedLink className={css.link} name="AreYouARestaurantPage">
        For Restaurants <img src={rightArrow} alt="arrow" />
      </NamedLink>
      <NamedLink className={css.link} name="LoginPage">
        Sign up/Sign in <img src={rightArrow} alt="arrow" />
      </NamedLink>
    </div>
  ) : null;

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.style.setProperty('--marketplaceBackground', '#fff');
    } else {
      document.documentElement.style.setProperty('--marketplaceBackground', '#000');
    }
  }, [isDarkMode]);

  return (
    <nav className={classes}>
      <div className={css.logoLinkWrapper}>
        {/* {currentPage !== "landingPage" ? <LinkedLogo
          className={css.logoLink}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        /> : */}
        <NamedLink
          name={(() => {
            if (currentUser?.attributes?.profile?.publicData?.userRole === 'customer')
              return 'LandingPage';
            else if (currentUser?.attributes?.profile?.publicData?.userRole === 'provider')
              return 'ManageListingsPage';
            else return 'LandingPage';
          })()}
          className={css.logoLink}
        >
          <IconCard brand="logo" />
        </NamedLink>
        <div className={css.bostonBox}>
          <span>BOSTON</span>
        </div>
        {/* } */}
      </div>
      {/* {search} */}
      <div className={css.desktopFilter}>
        {currentUser?.attributes?.profile?.publicData?.userRole !== 'provider' &&
          !(pathname.includes('sign') || pathname.includes('login')) &&
          filter}
      </div>
      {/* <SearchComponent  onSubmit={''}/> */}
      {/* <div className={css.searchBarBox}>
        <div className={css.dateInput}>
          <input
            type="date"
            name='date'
          />
        </div>
        <div className={css.capacityInput}>
          <input
            type="text"
            name='capacity'
            placeholder='Any Capacity'
          />
        </div>
        <div className={css.capacityInput}>
          <input
            type="text"
            name='space'
            placeholder='Any Space'
          />
        </div>
        <div className={css.searchButton}>
          <button>
            <IconCard brand="searchright" />
          </button>
        </div>
      </div> */}
      <div className={css.rightWrapper}>
        {currentUser && currentUser?.attributes?.profile?.publicData?.userRole === 'provider' ? (
          <NamedLink className={css.newListingButton} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        ) : null}

        {LoginMenu}
        {profileMenu}
      </div>
      {/* {
          !isAuthenticated ? <div> <NamedLink className={css.newListingButton} name="BecomeAExpertPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.expertTitle" />
            </span>
          </NamedLink></div> : null
        } */}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
