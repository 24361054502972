import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InlineTextButton, NamedLink } from '../../components';

import css from './TabNav.module.css';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { logout } from '../../ducks/auth.duck';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    para,
    selected,
    linkProps,
    editListingTab,
    index,
    profileTabs,
    icon,
    inboxTab,
  } = props;

  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });

  const listingslinkClasses = classNames(css.listingLink, {
    [css.listingSelectedLink]: selected,
    [css.listingDisabled]: disabled,
  });

  const profileLinkClasses = classNames(css.profileLink, {
    [css.profileSelectedLink]: selected,
    [css.profileDisabled]: disabled,
  });

  const inboxLinkClasses = classNames(css.inboxLink, {
    [css.inboxSelectedLink]: selected,
    [css.inboxDisabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink
        className={
          editListingTab
            ? listingslinkClasses
            : profileTabs
            ? profileLinkClasses
            : inboxTab
            ? inboxLinkClasses
            : linkClasses
        }
        {...linkProps}
      >
        {editListingTab && (
          <div className={selected ? css.selectedCount : css.defaultSelectedCount}>{index}</div>
        )}
        <div>
          <div className={editListingTab ? css.editListingLinkHeading : css.tabHeading}>
            {profileTabs && (
              <span className={selected ? css.selectProfileIocn : css.unselectProfileIcon}>
                {icon}
              </span>
            )}
            {text}
          </div>
          {editListingTab && (
            <div className={editListingTab ? css.editListingLinkSubHeading : css.paraHeading}>
              {para}
            </div>
          )}
        </div>
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    editListingTab,
    profileTabs,
    icon,
    inboxTab,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  const dispatch = useDispatch();
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;

        return (
          <Tab
            key={id}
            id={id}
            className={tabClasses}
            editListingTab={editListingTab}
            profileTabs={profileTabs}
            icon={icon}
            inboxTab={inboxTab}
            index={index + 1}
            {...tab}
          />
        );
      })}

      {profileTabs && (
        <InlineTextButton
          rootClassName={css.logoutButton}
          onClick={() => {
            dispatch(logout());
          }}
          className={css.profileLogout}
        >
          <FormattedMessage id="TopbarDesktop.logout" />
        </InlineTextButton>
      )}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
