// import { parse } from 'querystring';
import { encrypt, fetchUrl } from '../../util/api';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { parse } from 'query-string';
import { ASSET_NAME } from '../LandingPage/LandingPage.duck';

export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const PROVIDER_REQUEST = 'app/AuthenticationPage/PROVIDER_REQUEST';
export const PROVIDER_SUCCESS = 'app/AuthenticationPage/PROVIDER_SUCCESS';
export const PROVIDER_ERROR = 'app/AuthenticationPage/PROVIDER_ERROR';

const initialState = {
  isdecrypted: null,
}
const AuthenticationPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PROVIDER_REQUEST:
      return {
      };
    case PROVIDER_SUCCESS:
    
      return {
        ...state,
        isdecrypted: payload.eamilDecrypted
      };
    case PROVIDER_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state};
    default:
    
      return state;
  }
};

export default AuthenticationPageReducer;

export const providerRequest = () => ({ type: PROVIDER_REQUEST });
export const providerSuccess = (res) => ({ type: PROVIDER_SUCCESS, payload: res});

export const providerError = error => ({ type: PROVIDER_ERROR, payload: error, error: true });
export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};




export const fetchAutoLoginUrl = (params) => (dispatch) => {
  return fetchUrl(params).then(res => {
    const inviteEmail = res.responseData.email
    dispatch(providerSuccess({  eamilDecrypted: inviteEmail}))
    return res;
  }).catch((e) => {

    //return storableError(e)
  });
}



export const loadData = (params, search) => async (dispatch) => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
    landingPage: `content/pages/${ASSET_NAME}.json`
  };
  const searchValues = search && search.length ? parse(search.slice(1)) : {};
 
  if (searchValues && searchValues.code) {
    await dispatch(fetchAutoLoginUrl({ providerUrl: searchValues.code, id: searchValues.id }));
  }
  return (dispatch(fetchPageAssets(pageAsset, true)))
};
