import { storableError } from '../../util/errors';

// ================ Action types ================ //
export const GET_ONBOARDING_STATUS_REQUEST = 'app/OnBoarding/GET_EMAIL_REQUEST';
export const GET_ONBOARDING_STATUS_SUCCESS = 'app/OnBoarding/GET_EMAIL_SUCCESS';
export const GET_ONBOARDING_STATUS_ERROR = 'app/OnBoarding/GET_EMAIL_ERROR';
export const SET_PASSWORD_REQUEST = 'app/OnBoarding/GET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'app/OnBoarding/GET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'app/OnBoarding/GET_PASSWORD_ERROR';
export const MARK_ONBOARDING_TRUE = 'app/OnBoarding/MARK_ONBOARDING_TRUE ';
export const MARK_READY = 'app/OnBoarding/MARK_READY';
export const MARK_READY_ERROR = 'app/OnBoarding/MARK_READY_ERROR';
export const RESET_STATES = 'app/OnBoarding/RESET_STATES';

// ================ Reducer ================ //

const initialState = {
  getOnBoardingStatusProgress: null,
  getOnBoardingStatusSuccess: null,
  getOnBoardingStatusError: null,
  setPasswordProgress: null,
  setPasswordSuccess: null,
  setPasswordError: null,

  onBoarding: false,
  password: null,
  readyForOnBoarding: false,
  readyError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_ONBOARDING_STATUS_REQUEST:
      return {
        ...state,
        getOnBoardingStatusProgress: true,
        getOnBoardingStatusSuccess: null,
        getOnBoardingStatusError: false,
      };
    case GET_ONBOARDING_STATUS_SUCCESS:
      return {
        ...state,
        getOnBoardingStatusProgress: null,
        getOnBoardingStatusSuccess: true,
        getOnBoardingStatusError: null,
      };
    case GET_ONBOARDING_STATUS_ERROR:
      return {
        ...state,
        getOnBoardingStatusProgress: null,
        getOnBoardingStatusSuccess: null,
        getOnBoardingStatusError: payload,
      };
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        setPasswordProgress: true,
        setPasswordSuccess: null,
        setPasswordError: false,
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordProgress: null,
        setPasswordSuccess: true,
        setPasswordError: null,
      };
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        setPasswordProgress: null,
        setPasswordSuccess: null,
        setPasswordError: payload,
      };
    case MARK_ONBOARDING_TRUE:
      console.log('Marking on boarding');
      return {
        ...state,
        onBoarding: true,
      };
    case MARK_READY:
      return {
        ...state,
        readyForOnBoarding: true,
        readyError: null,
      };
    case MARK_READY_ERROR:
      return {
        ...state,
        readyError: payload,
        readyForOnBoarding: false,
      };
    case RESET_STATES:
      return {
        getOnBoardingStatusProgress: null,
        getOnBoardingStatusSuccess: null,
        getOnBoardingStatusError: null,
        setPasswordProgress: null,
        setPasswordSuccess: null,
        setPasswordError: null,

        onBoarding: false,
        password: null,
        readyForOnBoarding: false,
        readyError: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getOnBoardingRequest = () => ({ type: GET_ONBOARDING_STATUS_REQUEST });
export const getOnBoardingSuccess = () => ({ type: GET_ONBOARDING_STATUS_SUCCESS });
export const getOnBoardingError = error => ({ type: GET_ONBOARDING_STATUS_ERROR, payload: error });
export const setPasswordRequest = () => ({ type: SET_PASSWORD_REQUEST });
export const setPasswordSuccess = () => ({ type: SET_PASSWORD_SUCCESS });
export const setPasswordError = error => ({ type: SET_PASSWORD_ERROR, payload: error });
export const markOnBoardingTrue = () => ({ type: MARK_ONBOARDING_TRUE });
export const markReady = () => ({ type: MARK_READY });
export const markReadyError = err => ({ type: MARK_READY_ERROR, payload: err });
export const resetStates = () => ({ type: RESET_STATES });

// ================ Thunks ================ //

export const makeOnBoarding = userId => async (dispatch, getState, sdk) => {
  dispatch(getOnBoardingRequest());
  const res = await sdk.users
    .show({ id: userId })
    .then(response => {
      dispatch(getOnBoardingSuccess());
      return response.data.data;
    })
    .catch(error => {
      console.error('Error fetching user:', error);
      dispatch(getOnBoardingError(storableError(error)));
      throw error;
    });

  // console.log(res);
  if (res?.attributes?.profile?.publicData?.userRole == 'provider') {
    if (res?.attributes?.profile?.publicData?.onBoarded == false) {
      dispatch(markReady());
    } else if (res?.attributes?.profile?.publicData?.onBoarded) {
      dispatch(markOnBoardingTrue());
    }
  } else dispatch(getOnBoardingError('User role not valid!'));
};

export const changePassword = (userEmail, password) => async (dispatch, getState, sdk) => {
  dispatch(setPasswordRequest());

  try {
    await sdk
    .login({ username: userEmail, password: process.env.REACT_APP_SHARETRIBE_DEFAULT_PASSWORD })
    // .login({ username: userEmail, password: 'User@123' })
      .then(() => {
        sdk.currentUser
          .changePassword({
            currentPassword: process.env.REACT_APP_SHARETRIBE_DEFAULT_PASSWORD,
            // currentPassword: 'User@123',
            newPassword: password,
          })
          .then(() => {
            sdk.currentUser
              .updateProfile({
                publicData: {
                  onBoarded: true,
                },
              })
              .then(() => {
                dispatch(setPasswordSuccess());
                dispatch(resetStates());
              })
              .catch(err => {
                console.log(err);
                dispatch(setPasswordError(storableError(err)));
              });
          });
      })
      .catch(err => {
        console.error(err);
        dispatch(setPasswordError('Error Logging in using default password!'));
      });
  } catch (err) {
    console.log(err);
    dispatch(setPasswordError(storableError(err)));
  }
};
