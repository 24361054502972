import React, { useState } from 'react';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  FieldCheckbox,
  FieldDateInput,
  FieldDateRangeController,
  FieldDateRangeInput,
  FieldLocationAutocompleteInput,
  FieldRadioButton,
  FieldTextInput,
  Form,
  IconCard,
  OutsideClickHandler,
} from '../../../../components';
import { listingFields, priceCatergory } from '../../../../config/configListing';

import css from './Topbarfilter.module.css';
import { FieldCheckboxGroup } from '../../../../components';
import { useConfiguration } from '../../../../context/configurationContext';
import moment from 'moment';

const SearchFieldsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize={true}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        form,
        formId,
        values,
        autoFocus,
        invalid,
      } = fieldRenderProps;
      const buyout = priceCatergory[0].enumOptions;
      const [showContent, setShowContent] = useState(false);
      const handleButtonClick = () => {
        setShowContent(!showContent); // Toggle the state
      };

      const [showdate, setShowDates] = useState(false);
      const handledateClick = () => {
        setShowDates(!showdate); // Toggle the state
      };

      const [showkeyword, setShowKeyword] = useState(false);
      const handlekeywordClick = () => {
        setShowKeyword(!showkeyword); // Toggle the state
      };
      const config = useConfiguration();
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';

      const formatSelectedDateRange = date => {
        const start = moment(date).format('ddd, DD MMM YYYY');

        return start;
      };

      return (
        <Form onSubmit={handleSubmit}>
          <div className={css.searchBarBox}>
            <div className={values.date && values.date.date ? css.datePlaceInput : css.dateInput}>
              <div className={css.taskSelect} onClick={handledateClick}>
                {values.date && values.date.date ? (
                  <div className={css.dateText}>{formatSelectedDateRange(values.date.date)}</div>
                ) : (
                  <div className={css.datePlaceholder}>
                    <IconCard brand="calender" />
                    <span>All Dates</span>
                  </div>
                )}
              </div>
              {showdate && (
                <OutsideClickHandler onOutsideClick={handledateClick} className={css.dateDropdown}>
                  <FieldDateRangeController
                    name="date"
                    id="date"
                    minimumNights={isNightlyMode ? 1 : 0}
                  />
                </OutsideClickHandler>
              )}
            </div>
            <label htmlFor="keyword" className={css.capacityInputWrapper}>
              <IconCard brand="time" />
              <FieldTextInput
                onClick={handlekeywordClick}
                className={css.taskInput}
                id={'keyword'}
                name={'keyword'}
                type={'text'}
                label={''}
                placeholder="Any Capacity"
              />
            </label>
            <div className={css.capacityInput}>
              <div className={css.taskSelect} onClick={handleButtonClick}>
                {values.buyout?.length > 0 ? (
                  `(${values.buyout
                    .map(v => {
                      if (v === 'one_doller') return '$';
                      else if (v === 'two_doller') return '$$';
                      else if (v === 'three_doller') return '$$$';
                      else if (v === 'four_doller') return '$$$$';
                      else return 'Any Space';
                    })
                    .join(', ')})`
                ) : (
                  <div className={css.spacePlaceholder}>
                    <IconCard brand="anySpace" />

                    <span>Any Space</span>
                  </div>
                )}
              </div>
              {showContent && (
                <OutsideClickHandler onOutsideClick={handleButtonClick} className={css.spaceValue}>
                  {buyout
                    .filter(e => e.option) // Filter out elements with empty 'option' property
                    .map(e => (
                      <FieldRadioButton
                        key={e.label}
                        className={css.taskCheckbox}
                        id={`buyout_${e.label}`}
                        name="buyout"
                        label={e.label}
                        options={e.option}
                        value={e.option}
                      />
                    ))}
                </OutsideClickHandler>
              )}
            </div>
            <div className={css.searchButton}>
              <button
                className={css.searchDesktopButton}
                onClick={() => handleSubmit}
                button="submit"
              >
                <IconCard brand="search" />
              </button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

const SearchComponent = compose(injectIntl)(SearchFieldsFormComponent);
SearchComponent.displayName = 'Search Component';
export default SearchComponent;
