import appSettings from '../../config/settings';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
export const ASSET_NAME = 'landing-page';
import { parse } from '../../util/urlHelpers';
export const SEARCH_LISTINGS_REQUEST = 'app/LandingPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/LandingPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/LandingPage/SEARCH_LISTINGS_ERROR';

const RESULT_PAGE_SIZE = 15;

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);
const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
  
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };

    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };

    case SEARCH_LISTINGS_ERROR:
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
      };
    default:
      return state;
  }
};

export default landingPageReducer;

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { perPage, price, dates, ...rest } = searchParams;
  
  

  const params = {
    pub_listingType: 'business',
    ...rest,

    per_page: perPage,
  };

  

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};
// export const loadData = (params, search) => dispatch => {
//   const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
//   return dispatch(fetchPageAssets(pageAsset, true));
// };
export const loadData =  (params, search) => (dispatch, getState) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = appSettings.sortSearchByDistance && origin ? { origin } : {};
  
  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, true)),
    dispatch(searchListings({
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small',
        'variants.square-small2x'],
      // 'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      // ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      // ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
      pub_featured: true,
    })),
  ])
};