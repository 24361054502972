import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, buyoutOrderData, listing } = props;
  const { publicData } = listing?.attributes || {};
  const { dayAvailability } = publicData || {};
  const { buyoutCapacity, buyoutName,currentListingId, totalBuyoutSelectedSeats,bookingStartTime ,bookingUpdatedId,
    bookingEndTime , bookingStartDate, bookingEndDate } = buyoutOrderData || {};
 const startDayOfWeek = new Date(bookingStartDate?.date).toLocaleDateString('en-US', { weekday: 'short' });
 const endDayOfWeek = new Date(bookingEndDate?.date).toLocaleDateString('en-US', { weekday: 'short' });

 // Convert dayOfWeek to lowercase
 const startDayOfWeekLower = startDayOfWeek.toLowerCase();
 const endDayOfWeekLower = endDayOfWeek.toLowerCase();
 
 // Find the matching day based on the day of the week
 const matchingDay = dayAvailability?.find(day => {
  const dayOfWeek = day?.dayOfWeek?.toLowerCase(); // Use lowercase here
  return dayOfWeek === startDayOfWeekLower || dayOfWeek === endDayOfWeekLower;
});

  // Extract values from matchingDay based on buyoutName
  const buyoutValues = matchingDay ? matchingDay[buyoutName] : null;
  const minimumValue = buyoutValues ? buyoutValues.minimum : null;
  const roomRentalFeeValue = buyoutValues ? buyoutValues.roomRentalFee : null;
  const serviceValue = buyoutValues ? buyoutValues.service : null;


const transformOptionLabel = option => {
  // Split the option string by dashes, capitalize each word, and join them back with a space
  return option
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
      ? 'OrderBreakdown.baseUnitDay'
      : isHourly
        ? 'OrderBreakdown.baseUnitHour'
        : 'OrderBreakdown.baseUnitQuantity';


  // Find correct line-item for the given code prop.
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return quantity && total ? (
    <div>
         {buyoutName && buyoutCapacity > 0 && (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id={transformOptionLabel(buyoutName)} values={{ buyoutName }} />
          </span>
          <span className={css.itemValue}>{startDayOfWeek}</span>
        </div>
      )}
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.LineItemBasePriceMaybe.depositDue" values={{ unitPrice, quantity }} />
        </span>
        <span className={css.itemValue}>{unitPrice}</span>
      </div>
   
      {/* {buyoutValues && (
        <div>
          <div className={css.lineItem}> 
            <span className={css.itemLabel}>
              <FormattedMessage id="OrderBreakdown.LineItemBasePriceMaybe.minimumSpend" />
            </span>
            <span className={css.itemValue}>{minimumValue}</span>
          </div>
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id= "OrderBreakdown.LineItemBasePriceMaybe.roomRentalFee"/>
            </span>
            <span className={css.itemValue}>{roomRentalFeeValue}</span>
          </div>
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage id="OrderBreakdown.LineItemBasePriceMaybe.serviceFees" />
            </span>
            <span className={css.itemValue}>{serviceValue}</span>
          </div>
        </div>
      )}*/}
      {totalBuyoutSelectedSeats && (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.LineItemBasePriceMaybe.selectedBuyoutSeat" values={{ totalBuyoutSelectedSeats }} />
          </span>
          <span className={css.itemValue}>{totalBuyoutSelectedSeats}</span>
        </div>
      )} 
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
